import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from "@mui/material";
import moment from "moment";
import { NoWrapType } from "../../utils/NoWrapType";

const VehiclesTable = ({ list, selectedRow, setSelectedRow, page, rowsPerPage, setPage, setRowsPerPage }) => {
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <TableContainer component={Paper}>
            <Table size="small" aria-label="a dense table">
                <TableHead sx={{
                    backgroundColor: 'primary.main',
                    color: 'primary.contrastText'
                }}>
                    <TableRow>
                        <TableCell sx={{ color: "inherit", width: 180 }} align="left">Depot</TableCell>
                        <TableCell sx={{ color: "inherit", width: 180 }} align="left">Vehicle Group</TableCell>
                        <TableCell sx={{ color: "inherit", width: 180 }} align="left">Available from</TableCell>
                        <TableCell sx={{ color: "inherit", width: 200 }} align="left">Make</TableCell>
                        <TableCell sx={{ color: "inherit", width: 200 }} align="left">Type</TableCell>
                        <TableCell sx={{ color: "inherit", width: 200 }} align="left">Registration</TableCell>
                        <TableCell sx={{ color: "inherit", width: 200 }} align="left">Serial ID</TableCell>
                        <TableCell sx={{ color: "inherit", width: 200 }} align="left">Moffett Trailer</TableCell>
                        <TableCell sx={{ color: "inherit", width: 250 }} align="left">Moffett</TableCell>
                        <TableCell sx={{ color: "inherit", width: 200 }} align="left">Phone Number</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {list && (rowsPerPage > 0
                        ? list.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        : list
                    ).map((row, index) => (
                        <TableRow
                            hover={selectedRow && selectedRow.id === row.id ? false : true}
                            onClick={() => { setSelectedRow(row) }}
                            key={index}
                            sx={{
                                '&:last-child td, &:last-child th': { border: 0 },
                                cursor: selectedRow && selectedRow.id === row.id && 'pointer',
                                backgroundColor: selectedRow && selectedRow.id === row.id && /*color(row, 'clientId')*/ 'primary.main',
                                color: selectedRow && selectedRow.id === row.id && 'primary.contrastText',
                            }}
                        >
                            <TableCell sx={{ color: 'inherit', maxWidth: 200 }} align="left"><NoWrapType text={row.depot} /></TableCell>
                            <TableCell sx={{ color: 'inherit', maxWidth: 120 }} align="left"><NoWrapType text={row.vehicleGroup} /></TableCell>
                            <TableCell sx={{ color: 'inherit', maxWidth: 120 }} align="left"><NoWrapType text={moment(row.availableFrom).format('DD-MM-YYYY')} /></TableCell>
                            <TableCell sx={{ color: 'inherit', maxWidth: 120 }} align="left"><NoWrapType text={row.make} /></TableCell>
                            <TableCell sx={{ color: 'inherit', maxWidth: 120 }} align="left"><NoWrapType text={row.type} /></TableCell>
                            <TableCell sx={{ color: 'inherit', maxWidth: 120 }} align="left"><NoWrapType text={row.registration} /></TableCell>
                            <TableCell sx={{ color: 'inherit', maxWidth: 120 }} align="left"><NoWrapType text={row.serialId} /></TableCell>
                            <TableCell sx={{ color: 'inherit', maxWidth: 120 }} align="left"><NoWrapType text={row.moffettTrailer} /></TableCell>
                            <TableCell sx={{ color: 'inherit', maxWidth: 120 }} align="left"><NoWrapType text={row.moffett} /></TableCell>
                            <TableCell sx={{ color: 'inherit', maxWidth: 120 }} align="left"><NoWrapType text={row.phoneNumber} /></TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <TablePagination
                component="div"
                count={list ? list.length : -1}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </TableContainer>

    );
}


export default VehiclesTable;