import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import SaveIcon from '@mui/icons-material/Save';
import { Alert, Autocomplete, Button, Chip, Container, Divider, FormControlLabel, Grid, IconButton, Paper, Snackbar, Stack, Switch, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { putRequest, transportRequestURL } from '../../db/apiHandler';
import { editTransportRequisitionUserRights, transportRequisitionRights } from "../../store/transportRequisitionSlice";
import { userDetails } from '../../store/userSlice';

import styled from '@emotion/styled';
import { DragDropContext, Draggable } from 'react-beautiful-dnd';
import DroppableStrict from './DroppableStrict';

const TaskColumnStyles = styled.div`
  margin: 8px;
  display: flex;
  width: 100%;
  min-height: 80vh;
`;
const TaskList = styled.div`
  min-height: 100px;
  display: flex;
  flex-direction: column;
  background: #f3f3f3;
  min-width: 32%;
  border-radius: 5px;
  padding: 15px 15px;
  margin-right: 45px;
`;

const Settings = () => {

    const dispatch = useDispatch();

    const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [openConfirmationSnackbar, setOpenConfirmationSnackbar] = useState(false);

    const [columnsSelected, setColumnsSelected] = useState([]);
    const [selectedColumn, setSelectedColumn] = useState('');
    const [autoRefresh, setAutoRefresh] = useState(false);

    let columnOptions = columnsList.filter(el => el.columnName !== 'Property Number').map(el => el.columnName);

    const s_user_rights = useSelector(transportRequisitionRights);
    const s_user = useSelector(userDetails);

    useEffect(() => {
        columnsSelected.forEach(element => {
            const index = columnOptions.indexOf(element);
            if (index > -1) {
                columnOptions.splice(index, 1);
            }
        });
    }, [columnsSelected])

    useEffect(() => {
        if (s_user_rights.columnSelection) {
            setColumnsSelected(s_user_rights.columnSelection.split(","));
        }
        if (s_user_rights.autoRefresh) {
            setAutoRefresh(true);
        }
    }, [s_user_rights])


    const handleRemoveColumn = (element) => {
        let a = [...columnsSelected];
        const index = a.indexOf(element);
        if (index > -1) {
            a.splice(index, 1);
        }

        setColumnsSelected(a);
    }
    const handleAddColumn = () => {
        let a = [...columnsSelected];
        a.push(selectedColumn);

        setSelectedColumn('');
        setColumnsSelected(a);
    }

    const handleAutocompleteChanged = (e, values) => {
        setSelectedColumn(values);
    }

    const handleSave = async () => {
        let text = columnsSelected.join(",");
        let updateEntry = { ...s_user_rights };
        updateEntry.columnSelection = text;
        updateEntry.autoRefresh = autoRefresh;
        let success = true;
        const response = await putRequest(`${transportRequestURL}/updateUserRights/${s_user.uid}`, updateEntry)
            .catch((error) => {
                success = false;
                console.error('error', error)
                setErrorMessage(error);
                setOpenErrorSnackbar(true);
            });
        if (success) {
            dispatch(editTransportRequisitionUserRights(response.response));
            setOpenConfirmationSnackbar(true);
        }

    }

    const handleCloseSnackbar = () => {
        setOpenErrorSnackbar(false);
        setOpenConfirmationSnackbar(false);
    };

    const onDragEnd = (result) => {
        if (!result.destination) return;
        const { source, destination } = result;
        if (source.index !== destination.index) {
            let columnsCopy = [...columnsSelected];
            let item = columnsCopy[source.index];
            columnsCopy.splice(source.index, 1);
            columnsCopy.splice(destination.index, 0, item);
            setColumnsSelected(columnsCopy);
        }
    };

    return (
        <Box>
            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <Paper sx={{ p: 1 }}>

                        <Divider sx={{ color: 'primary.main', fontWeight: 'bold', my: 2 }}>Manage Columns displayed on Tracker</Divider>
                        <Grid container>
                            <Grid item xs={9}>
                                <Stack direction='row' spacing={1} sx={{ alignSelf: "start" }} >
                                    <Autocomplete
                                        name='columnSelected'
                                        disablePortal
                                        freeSolo
                                        size='small'
                                        options={columnOptions ? columnOptions.filter(el => columnsSelected.findIndex(elem => elem === el) < 0) : []}
                                        sx={{ width: 300 }}
                                        value={selectedColumn}
                                        onChange={handleAutocompleteChanged}
                                        renderInput={(params) => <TextField {...params} label="Search column name ..." />}
                                    />
                                    <IconButton disabled={selectedColumn === ''} aria-label="comments" variant='contained' color='primary' size="large" sx={{ marginLeft: "auto" }} onClick={handleAddColumn}>
                                        <AddCircleOutlineIcon />
                                    </IconButton>

                                </Stack>
                            </Grid>
                            <Grid item xs={3}>
                                <Stack direction='row' spacing={1} sx={{ alignSelf: "end" }} >
                                    <Button aria-label="comments" variant='contained' color='primary' size="large" sx={{ marginLeft: "auto" }} onClick={handleSave} startIcon={<SaveIcon />}>
                                        SAVE
                                    </Button>
                                </Stack>
                            </Grid>
                        </Grid>

                        <Box direction="row" m={1} pt={1} spacing={3} sx={{ justifyContent: "space-evenly", alignSelf: "center", textAlign: "webkit-center" }} >
                            <DragDropContext
                                onDragEnd={(result) => onDragEnd(result)}
                            >
                                <DroppableStrict key={'key'} droppableId={'id1'} >
                                    {(provided) => (
                                        <div
                                            key={'key1'}
                                            ref={provided.innerRef}
                                            {...provided.droppableProps}
                                        >
                                            <Stack /* direction={'row'} */>
                                                {columnsSelected.map((element, index) => (
                                                    <Draggable key={element} draggableId={element.toString()} index={index}>

                                                        {(provided) => (
                                                            <div
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                            >
                                                                <Chip sx={{ margin: 0.2 }} key={element} label={element} color="primary" variant="outlined" onDelete={() => { handleRemoveColumn(element) }} />
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                ))}
                                            </Stack>
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </DroppableStrict>
                            </DragDropContext>

                            {/* {columnsSelected.map((element) => (
                    <Chip sx={{ margin: 0.2 }} key={element} label={element} color="primary" variant="outlined" onDelete={() => { handleRemoveColumn(element) }} />
                ))} */}
                        </Box>
                    </Paper>
                </Grid>
                {/* <Grid item xs={6}>
                    <Paper sx={{ p: 1 }}>
                        <Divider sx={{ color: 'primary.main', fontWeight: 'bold', my: 2 }}>Auto-refresh</Divider>
                        <FormControlLabel labelPlacement="start" control={<Switch color="primary" checked={autoRefresh} onChange={() => setAutoRefresh(!autoRefresh)} />} label="Auto refresh the tracker data every 30 seconds" />
                    </Paper>
                </Grid> */}
            </Grid>


            <Snackbar open={openErrorSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <Alert severity="error" sx={{ width: '100%' }} variant="filled">
                    {errorMessage}
                </Alert>
            </Snackbar>

            <Snackbar open={openConfirmationSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <Alert severity="success" sx={{ width: '100%' }} variant="filled">
                    Settings successfully saved.
                </Alert>
            </Snackbar>

        </Box>
    )

}

export default Settings;

export const columnsList = [
    { columnName: "Despatch note no", field: "id", headerMaxWidth: 20, rowMaxWidth: 120 },
    { columnName: "Consignee Company", field: "customerName", headerMaxWidth: 20, rowMaxWidth: 120 },
    { columnName: "Project Name", field: "projectName", headerMaxWidth: 20, rowMaxWidth: 120 },
    { columnName: "Action", field: "action", headerMaxWidth: 20, rowMaxWidth: 120 },
    { columnName: "Job No.", field: "jobNo", headerMaxWidth: 20, rowMaxWidth: 120 },
    { columnName: "Sales Order Number", field: "salesOrderNumber", headerMaxWidth: 20, rowMaxWidth: 120 },
    { columnName: "Store No", field: "customerAddressID", headerMaxWidth: 20, rowMaxWidth: 120 },
    { columnName: "Store Name", field: "customerAddressID", headerMaxWidth: 20, rowMaxWidth: 120 },
    { columnName: "Property Number", field: "propertyNumber", headerMaxWidth: 20, rowMaxWidth: 120 },
    { columnName: "Earliest Delivery", field: "earliestDelivery", headerMaxWidth: 20, rowMaxWidth: 150 },
    { columnName: "Latest Delivery", field: "latestDelivery", headerMaxWidth: 20, rowMaxWidth: 150 },
    { columnName: "Address", field: "address", headerMaxWidth: 20, rowMaxWidth: 120 },
    { columnName: "Postcode", field: "postcode", headerMaxWidth: 20, rowMaxWidth: 130 },
    { columnName: "Description", field: "description", headerMaxWidth: 20, rowMaxWidth: 120 },
    { columnName: "Special Instructions", field: "specialInstructions", headerMaxWidth: 20, rowMaxWidth: 120 },
    { columnName: "Forklift Available", field: "forkliftAvailable", headerMaxWidth: 20, rowMaxWidth: 120 },
    { columnName: "Pallet Spaces", field: "palletSpaces", headerMaxWidth: 20, rowMaxWidth: 120 },
    { columnName: "Qty Pallet Standard", field: "qtyPalletStandard", headerMaxWidth: 20, rowMaxWidth: 120 },
    { columnName: "Qty Pallet Oversized", field: "qtyPalletOversized", headerMaxWidth: 20, rowMaxWidth: 120 },
    { columnName: "Total Weight", field: "totalWeight", headerMaxWidth: 20, rowMaxWidth: 120 },
    { columnName: "Max Vehicle Type", field: "maxVehicleType", headerMaxWidth: 20, rowMaxWidth: 120 },
    { columnName: "Dedicated Vehicle Required", field: "dedicatedVehicleRequired", headerMaxWidth: 20, rowMaxWidth: 120 },
    { columnName: "Labour On Site", field: "labourOnSite", headerMaxWidth: 20, rowMaxWidth: 120 },
    { columnName: "Location of Equipment", field: "equipmentLocation", headerMaxWidth: 20, rowMaxWidth: 120 },
    { columnName: "Project Number / CPAR", field: "projectNumberCpar", headerMaxWidth: 20, rowMaxWidth: 120 },
    { columnName: "Project Main Contractor", field: "projectMainContractor", headerMaxWidth: 20, rowMaxWidth: 120 },
    { columnName: "Loading Depot", field: "transportDepot", headerMaxWidth: 20, rowMaxWidth: 120 },
    { columnName: "Return Facility", field: "returnFacility", headerMaxWidth: 20, rowMaxWidth: 120 },
    { columnName: "SDR Form Received", field: "sdrFormReceived", headerMaxWidth: 20, rowMaxWidth: 120 },
    { columnName: "SDR Form Link", field: "sdrFormLink", headerMaxWidth: 20, rowMaxWidth: 120 },
    { columnName: "Export To Paragon", field: "exportToParagon", headerMaxWidth: 20, rowMaxWidth: 120 },
    // { columnName: "Designate Person for Loading", field: "loadingResponsiblePerson", headerMaxWidth: 20, rowMaxWidth: 120 },
    // { columnName: "Driver Loading Time", field: "driverLoadingTime", headerMaxWidth: 20, rowMaxWidth: 120 },
    { columnName: "Last updated", field: "lastUpdatedAt", headerMaxWidth: 20, rowMaxWidth: 150 },
    { columnName: "Status", field: "status", headerMaxWidth: 20, rowMaxWidth: 120 },
    { columnName: "Last Updated By", field: "lastUpdatedBy", headerMaxWidth: 20, rowMaxWidth: 120 },
    { columnName: "Moffett Details", field: "moffettDetails", headerMaxWidth: 20, rowMaxWidth: 120 },
    { columnName: "Late Requisition Reason", field: "lateRequisitionReason", headerMaxWidth: 20, rowMaxWidth: 120 },
    { columnName: "Date Notified", field: "dateNotified", headerMaxWidth: 20, rowMaxWidth: 150 },
    { columnName: "Created By", field: "userId", headerMaxWidth: 20, rowMaxWidth: 120 },
    { columnName: "Requestor Company", field: "user_company", headerMaxWidth: 20, rowMaxWidth: 120 },
    { columnName: "CM Name", field: "cm_name", headerMaxWidth: 20, rowMaxWidth: 120 },
    { columnName: "CM Email", field: "cm_email", headerMaxWidth: 20, rowMaxWidth: 120 },
    { columnName: "CM Company", field: "cm_company", headerMaxWidth: 20, rowMaxWidth: 120 },
    { columnName: "Site Contact Name", field: "deliveryContact", headerMaxWidth: 20, rowMaxWidth: 120 },
    { columnName: "Site Contact Number", field: "deliveryContactNumber", headerMaxWidth: 20, rowMaxWidth: 120 },
];

