import { createSlice } from "@reduxjs/toolkit";
import { formatDate } from "../utils/DateFormat";

const initialState = {
  requisitions: null,
  selectedRequisition: null,
  transportRequisitionRights: null,
  allUsersRights: null,
  // unapprovedRequests: null
};

export const transportRequisitionSlice = createSlice({
  name: 'transportRequisition',
  initialState,
  reducers: {
    setRequisitions: (state, action) => {
      state.requisitions = action.payload;
    },
    addNewestRequisition: (state, action) => {
      let requisitions = [...state.requisitions];
      const foundIndex = requisitions.findIndex(el => el.id === action.payload.id);
      if (foundIndex > -1) {
        requisitions[foundIndex] = action.payload;
      } else {
        requisitions.push(action.payload);
      }

      state.requisitions = requisitions;
    },
    setSelectedRequisition: (state, action) => {
      let requisition = action.payload.response.transportRequest;
      requisition.equipmentDetails = action.payload.response.transportRequestEquipmentDetails;
      state.selectedRequisition = requisition;
    },
    editSelectedRequisition: (state, action) => {
      // if (!action.payload.transportRequest.isApproved) {
      //   if (state.unapprovedRequests) {
      //     state.unapprovedRequests = [...state.unapprovedRequests, action.payload.transportRequest];
      //   } else {
      //     state.unapprovedRequests = [];
      //     let element = action.payload.transportRequest;
      //     state.unapprovedRequests.push(element);
      //   }
      // }
      let requisitions = [...state.requisitions];
      const foundIndex = state.selectedRequisition ? requisitions.findIndex(el => el.id === state.selectedRequisition.id) : requisitions.findIndex(el => el.id === action.payload.transportRequest.id);
      requisitions[foundIndex] = action.payload.transportRequest;
      requisitions[foundIndex].forkliftAvailable = action.payload.transportRequest.forkliftAvailable === 1 ? true : false;
      requisitions[foundIndex].dedicatedVehicleRequired = action.payload.transportRequest.dedicatedVehicleRequired === 1 ? true : false;
      requisitions[foundIndex].labourOnSite = action.payload.transportRequest.labourOnSite === 1 ? true : false;
      requisitions[foundIndex].sdrFormReceived = action.payload.transportRequest.sdrFormReceived === 1 ? true : false;
      // requisitions[foundIndex].isApproved = action.payload.transportRequest.isApproved === 1 ? true : false;
      requisitions[foundIndex].equipmentDetails = action.payload.transportRequestEquipmentDetails;
      state.requisitions = requisitions;

    },
    addRequisition: (state, action) => {
      // if (!action.payload.transportRequest.isApproved) {
      //   if (state.unapprovedRequests) {
      //     state.unapprovedRequests = [...state.unapprovedRequests, action.payload.transportRequest];
      //   } else {
      //     state.unapprovedRequests = [];
      //     let element = action.payload.transportRequest;
      //     state.unapprovedRequests.push(element);
      //   }
      // }
      if (state.requisitions) {
        state.requisitions = [...state.requisitions, action.payload.transportRequest];
        let requisitions = [...state.requisitions];
        const foundIndex = requisitions.findIndex(el => el.id === action.payload.transportRequest.id);
        requisitions[foundIndex].equipmentDetails = action.payload.transportRequestEquipmentDetails;
        state.selectedRequisition = requisitions[foundIndex];
      } else {
        state.requisitions = [];
        let element = action.payload.transportRequest;
        element.equipmentDetails = action.payload.transportRequestEquipmentDetails;
        state.requisitions.push(element);
      }

    },
    deleteSelectedRequisition: (state, action) => {
      state.requisitions = state.requisitions.filter(element => element.id !== Number(action.payload));
      state.selectedRequisition = null;
    },
    setTransportRequisitionRights: (state, action) => {
      if (action.payload && action.payload.response) {
        action.payload = {
          isAdmin: action.payload.response.isAdmin === 1 ? true : false,
          isContractManager: action.payload.response.isContractManager === 1 ? true : false,
          isTransportAdmin: action.payload.response.isTransportAdmin === 1 ? true : false,
          isFleetAdmin: action.payload.response.isFleetAdmin === 1 ? true : false,
          columnSelection: action.payload.response.columnSelection ? action.payload.response.columnSelection : '',
          autoRefresh: action.payload.response.autoRefresh === 1 ? true : false
        }
      } else {
        action.payload = {
          isAdmin: false,
          isContractManager: false,
          isTransportAdmin: false,
          isFleetAdmin: false,
          columnSelection: '',
          autoRefresh: false
        }
      }
      state.transportRequisitionRights = action.payload;
    },

    editTransportRequisitionUserRights: (state, action) => {
      if (action.payload) {
        action.payload = {
          isAdmin: action.payload.isAdmin === 1 ? true : false,
          isContractManager: action.payload.isContractManager === 1 ? true : false,
          isTransportAdmin: action.payload.isTransportAdmin === 1 ? true : false,
          isFleetAdmin: action.payload.isFleetAdmin === 1 ? true : false,
          columnSelection: action.payload.columnSelection ? action.payload.columnSelection : '',
          autoRefresh: action.payload.autoRefresh === 1 ? true : false
        }
      } else {
        action.payload = {
          isAdmin: false,
          isContractManager: false,
          isTransportAdmin: false,
          isFleetAdmin: false,
          columnSelection: '',
          autoRefresh: false
        }
      }
      state.transportRequisitionRights = action.payload;
    },

    exportCSVParagon: (state, action) => {
      let requisitions = [...state.requisitions];
      action.payload.forEach(el => {
        const foundIndex = requisitions.findIndex(elem => elem.id === el.id);
        requisitions[foundIndex].exportToParagon = 1;
        state.requisitions = requisitions;
      })
    },

    // approveRequests: (state, action) => {
    //   console.error(action);
    //   let requisitions = [];
    //   if (state.requisitions) {
    //     requisitions = [...state.requisitions];
    //   }

    //   action.payload.forEach(el => {
    //     if (requisitions.length > 0) {
    //       const foundIndex = requisitions.findIndex(elem => elem.id === el.id);
    //       requisitions[foundIndex].isApproved = (el.isApproved === 0 ? false : true);
    //       requisitions[foundIndex].approvedBy = el.approvedBy;
    //     }

    //     if (el.isApproved === 1) {
    //       state.unapprovedRequests = state.unapprovedRequests.filter(element => element.id !== Number(el.id));
    //     } else {
    //       let unapprovedRequestsList = [];
    //       unapprovedRequestsList = [...state.unapprovedRequests];
    //       const foundIndexUnappproved = unapprovedRequestsList.findIndex(elem => elem.id === el.id);
    //       unapprovedRequestsList[foundIndexUnappproved].isApproved = (el.isApproved === 0 ? false : true);
    //       unapprovedRequestsList[foundIndexUnappproved].approvedBy = el.approvedBy;
    //       state.unapprovedRequests = unapprovedRequestsList;
    //     }
    //   })


    // },

    // setUnapprovedRequests: (state, action) => {
    //   state.unapprovedRequests = action.payload;
    // },

    setAllUsersRights: (state, action) => {
      state.allUsersRights = action.payload
    },

    editUserRight: (state, action) => {
      let allUsersRights = [...state.allUsersRights];
      const foundIndex = allUsersRights.findIndex(el => el.userId === action.payload.response.userId);
      allUsersRights[foundIndex] = action.payload.response;
      state.allUsersRights = allUsersRights;
      state.selectedAddress = action.payload.response;
    },

  }
});

export const { setRequisitions, addNewestRequisition, setSelectedRequisition, editSelectedRequisition, addRequisition, deleteSelectedRequisition,
  setTransportRequisitionRights, editTransportRequisitionUserRights, exportCSVParagon,
  setAllUsersRights, editUserRight
  /*, approveRequests, setUnapprovedRequests*/ } = transportRequisitionSlice.actions;

export const requisitions = (state) => state.transportRequisition?.requisitions;
export const selectedRequisition = (state) => state.transportRequisition?.selectedRequisition;
export const transportRequisitionRights = (state) => state.transportRequisition?.transportRequisitionRights;
export const allUsersRights = (state) => state.transportRequisition?.allUsersRights;
// export const unapprovedRequests = (state) => state.transportRequisition?.unapprovedRequests;

export default transportRequisitionSlice.reducer;


