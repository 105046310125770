import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";

const LateRequisitionReasonDialog = ({ open, setOpen, lateRequisitionReason, setLateRequisitionReason, createTransportRequest, daysTillLatestDeliveryDate }) => {

    const handleReasonChange = (e) => {
        const value = e.target.value;
        setLateRequisitionReason(value);
    }

    const handleCloseConfirm = () => {
        setOpen(false);
        createTransportRequest();
    }

    return (
        <div>
            <Dialog
                open={open}
                fullWidth maxWidth="sm"
            >
                <DialogTitle>
                    Late Requisition Submitted
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Please provide a reason for submitting a late requisition (The latest delivery date within the following {daysTillLatestDeliveryDate === 0 || daysTillLatestDeliveryDate === 1 ? 'day' : daysTillLatestDeliveryDate + ' days'})
                    </DialogContentText>
                    <TextField required autoComplete='off' variant='standard' size='small' name='reason' label='Reason'
                        sx={{ marginTop: 1 }} value={lateRequisitionReason} onChange={handleReasonChange} fullWidth inputProps={{ maxLength: 300 }}/>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}>Cancel</Button>
                    <Button onClick={() => handleCloseConfirm()} autoFocus disabled={!lateRequisitionReason || lateRequisitionReason === '' || (lateRequisitionReason && lateRequisitionReason.length > 0 && lateRequisitionReason.replace(/\s/g, '').length === 0)}>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default LateRequisitionReasonDialog;