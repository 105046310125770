export const customers123 = [
    'APS Group',
    'Argos',
    'Aldi',
    'Waitrose',
    'Lidl',
    'CO-OP',
    'Conqueror Industries',
    'Fit Out Manufacturing',
    'GLG Sp. z o o',
    "Sainsbury's",
    'Tesco',
    'A.R. Davies Refinishing Supplies Limted',
    'Aalco',
    'Acrylex',
    'Adam Plastics',
    'Alser UK Ltd',
    'Amtico International',
    'AR18',
    'Ash Plastics',
    'Askew Glass Ltd',
    'ATL ASSOCIATES LTD',
    'Automated Wire Bending Ltd',
    'Axiom Retail Interiors LTD',
    'Bartuf',
    'Benchmarx Kitchens & Joinery',
    'Blok N Mesh Global Ltd',
    'Bryson Products Ltd',
    'C Brewer & Sons Limited',
    'Cameron Robb Limited',
    'CDM Plastics Ltd',
    'CHELSEA FARM CENTRE LTD',
    'CMT Industrial Powder Coaters Ltd',
    'COMMUNISIS',
    'Concept Data Display',
    'Cool Retail',
    'CWW Engineers Supply Co Ltd',
    'Dabew',
    'Delta Group',
    'Delta Sheet Metal',
    'DisplayPlan',
    'Dulux Decorator Centres',
    'Eden',
    'Eden Industries',
    'Elemental Fixtures Private Ltd',
    'Elmax',
    'Equipment Recycling Centre',
    'Europosm',
    'Exact Precision Engineering',
    'Firetherm Intumescent & Insulation Supplies Ltd',
    'Fit Out UK',
    'Gerflor Ltd',
    'GSM Electrical Supplies Ltd',
    'Hafle UK Ltd',
    'HL Displays (UK) Ltd',
    'Hollywood Monster LTD',
    'Hotlink',
    'Howdens Joinery Limited',
    'IMPACT COMMERCIAL SOLUTIONS LTD',
    'Inline Retail Solutions',
    'Innovation Laser',
    'International Decorative Surfaces',
    'ITAB UK Ltd',
    'Jewson Credit Services',
    'Jiangsu Tongdahui International Trade Co Ltd',
    'Kalpakaaru Projects PVT Ltd',
    'Kesslers',
    'Kwik Klik Hoardings Ltd',
    'L A Metals Ltd',
    'Loreal',
    'Lloyds Bank',
    'M & L sheet metal LTD',
    'McCue Corporation',
    'Tegometall Grantham Productions Ltd',
    'Millers Vanguard',
    'Moduleo Ltd',
    'N and C Building Products Ltd',
    'National Safety Supplies',
    'Novograf',
    'Oadby Plastics Limited',
    'Octink',
    'Office Depot',
    'Planners Services And Sundries Limited',
    'Precision Components & Equipment Ltd',
    'Retail Furniture',
    'Riolett Custom Aerosols',
    'RTC',
    'Screwfix Direct Limited',
    'Service Graphics LTD',
    'SFD',
    'Sig Trading Ltd',
    'Sigma',
    'Singleton Engineering',
    'SJ Harris Services',
    'Southern Computer Services',
    'Syncros',
    'T&T',
    'Techrail Retail Services Ltd',
    'Tesco Express Stores',
    'Tesco Large Formats',
    'The Larger Profile Ltd T/A LP Display',
    'The Sherwin-Williams Company',
    'Timbmet Ltd',
    'Travis Perkins Trading Limited',
    'Trellidor Uk Ltd',
    'Trimline',
    'Tubeway Sales Limited',
    'UK Fixings Ltd',
    'UK Point of Sale Group Ltd',
    'Unibox',
    'Universal Display',
    'Universal Site Supplies Ltd',
    'VJ Technology',
    'W Gadsby & Son',
    'Walker Bros (Elland) Ltd',
    'USE WBM001',
    'WANZL',
    'Whittan',
    'Willson Brown',
    'Windmill',
    'Wurth UK LTD',
    'XL Plastic Displays',
    'YTW International Co',
    'Zafety Supplies',
    'Orbital Fasteners',
    'Dexion GmbH (Constructor Group)',
    'DIEBOLD NIXDORF',
    'Liberty Precision Tubes Ltd',
    'Chambrelan UK Ltd',
    'AirTube Technologies Limited',
    'KAL Fixings Ltd',
    'Tensator Limited',
    'Premier Farnell UK Ltd (Farnell)',
    'Codelocks Ltd',
    'Millcourt Shopfittings Ltd',
    'Ergonomic Solutions (Western Europe) Ltd',
    'Cash Bases Ltd',
    'NEC- Visual',
    'POS Tuning',
    'Camelot',
    'Security Window Shutters Ltd',
    'Waivis Ltd',
    'Trigo',
    'Tubetek Limited',
    'SDI Displays Ltd',
    'FLUX DISPLAY LTD',
    'Dortek',
    'APEX GRANGE',
    'SELF ADHESIVE SUPPLIES',
    'Sloane evolution',
    'CDT ARCHITECTURAL',
    'Thermasolutions Internetional Ltd',
    'Display Designs',
    'Sameta Metal Pro PVT Ltd',
    'CEF',
    'Kew Electrical',
    'Doncaster IPORT'
]