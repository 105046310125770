import { Button } from '@mui/material'
import React from 'react'
import { addDoc, collection, doc, getDoc, getFirestore, setDoc } from "firebase/firestore";

const CreateFirebaseStructure = () => {
    const createDoc = async () => {

        const db = getFirestore();
        const docRef = await setDoc(doc(db, "fitout", "activityLog"), {})
    }

    
    return (
        <div>
            <Button onClick={createDoc}>
                Create doc
            </Button>
            <Button onClick={createActivityLog}>
                Create Activity Log
            </Button>
        </div>
    )
}

export const createActivityLog = async (url, requestType, body, requestTime, uid, createdAt) => {

    const db = getFirestore();

    const docRef = doc(db, "fitout", "activityLog");

    // Accessing a subcollection named 'activityLogs' within the document
    const landmarksCollectionRef = collection(docRef, "activityLogs");

    try {
        // Adding documents to the 'activityLogs' subcollection
        const docSnap = await addDoc(landmarksCollectionRef, {
            url: url,
            requestType: requestType,
            body: body,
            requestTime: requestTime,
            uid: uid,
            createdAt: createdAt
        });
        console.log("Document written with ID: ", docSnap.id);
    } catch (error) {
        console.error("Error adding document: ", error);
    }
}

export default CreateFirebaseStructure
