import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { Box, Divider, FormControlLabel, Grid, Paper, Stack, Switch, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { postRequest, putRequest, transportRequestURL } from '../../db/apiHandler';
import { allUsersRights, setAllUsersRights } from '../../store/transportRequisitionSlice';
import { users } from '../../store/userSlice';
import UserRightsTable from './UsersRightsTable';

const UsersRightsPage = () => {
    const dispatch = useDispatch();
    const [canLoadDoc, setCanLoadDoc] = useState(false);

    const [openFormDialog, setOpenFormDialog] = useState(false);

    const [usersRightsList, setUsersRighstList] = useState(null);
    const [usersList, setUsersList] = useState(null);

    const [selectedRow, setSelectedRow] = useState(null);

    const s_usersRights = useSelector(allUsersRights);
    const s_users = useSelector(users);

    const [searchValue, setSearchValue] = useState('');

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);

    useEffect(() => {
        setPage(0);
        if (s_users && s_usersRights) {
            let a = [];
            s_users.forEach(element => {
                a.push({
                    id: element.id,
                    username: element.userName,
                    email: element.userEmail,
                    userId: element.uid
                })
            });
            a = a.filter(el => (el.username !== null && el.username.toString().toLowerCase().indexOf(searchValue) !== -1) ||
                (el.userId !== null && el.userId.toString().toLowerCase().indexOf(searchValue) !== -1) ||
                (el.email !== null && el.email.toString().toLowerCase().indexOf(searchValue) !== -1)
            );

            setUsersList(a);
        }

    }, [s_usersRights, searchValue])



    useEffect(() => {
        if (selectedRow) {
            setCanLoadDoc(true);
        }
        else {
            setCanLoadDoc(false);
        }

    }, [selectedRow]);

    return (
        <Box>
            <Stack spacing={1} sx={{ display: "flex", flexDirection: "column", alignItems: "left" }} >

                <Stack direction='row' spacing={1} sx={{ alignItems: "center" }}>
                    <FilterAltIcon sx={{ fontSize: 32 }}></FilterAltIcon>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="searchValue"
                        name="searchValue"
                        label='Search user ...'
                        type="text"
                        size="small"
                        value={searchValue}
                        onChange={(e) => { setSearchValue((e.target.value).toLowerCase()); setSelectedRow(null) }}
                    />
                </Stack>
                <Grid container spacing={1}>
                    <Grid item xs={selectedRow ? 7 : 12}>
                        <Paper sx={{ p: 1 }}>
                            <UserRightsTable list={usersList} page={page} rowsPerPage={rowsPerPage} selectedRow={selectedRow} setSelectedRow={setSelectedRow} setPage={setPage} setRowsPerPage={setRowsPerPage} />

                        </Paper>

                    </Grid>
                    {selectedRow ?
                        <Grid item xs={5}>
                            <UserRightView uid={selectedRow.userId} />
                        </Grid> : null}
                </Grid>
            </Stack>
        </Box >
    )
}


const UserRightView = ({ uid }) => {

    const dispatch = useDispatch();
    const s_usersRights = useSelector(allUsersRights);

    const handleChangeRights = async (targetRight) => {
        let obj = {
            userId: uid,
            isAdmin: false,
            isTransportAdmin: false,
            isContractManager: false,
            isFleetAdmin: false
        }
        if (s_usersRights.find(el => el.userId === uid) && s_usersRights.find(el => el.userId === uid)[targetRight]) {
            obj[targetRight] = false;
        } else {
            obj[targetRight] = true;
        }
        if (s_usersRights.find(el => el.userId === uid)) {
            const response = await putRequest(`${transportRequestURL}/appRights/${obj.userId}`, obj)
                .catch((error) => {
                    console.error('error', error)
                });
            if (response.success) {
                dispatch(setAllUsersRights(response.response));
            } else {
                console.error('ERROR update user rights');
            }

        } else {
            const response = await postRequest(`${transportRequestURL}/appRights`, obj)
                .catch((error) => {
                    console.error('error', error)
                });
            if (response.success) {
                dispatch(setAllUsersRights(response.response));
            } else {
                console.error('ERROR creating user rights');
            }
        }


    }

    return (
        <Paper sx={{ p: 2 }}>
            <Divider sx={{ color: 'primary.main', fontWeight: 'bold' }}>Transport App</Divider>
            <Stack sx={{ p: 1 }} direction={'row'}>
                <FormControlLabel labelPlacement="start" control={<Switch color="primary"
                    checked={s_usersRights.find(el => el.userId === uid) && s_usersRights.find(el => el.userId === uid).isAdmin}
                    onChange={() => handleChangeRights('isAdmin')} />} label="Master Admin" />
                <FormControlLabel labelPlacement="start" control={<Switch color="primary"
                    checked={s_usersRights.find(el => el.userId === uid) && s_usersRights.find(el => el.userId === uid).isTransportAdmin}
                    onChange={() => handleChangeRights('isTransportAdmin')} />} label="Transport Admin" />
                <FormControlLabel labelPlacement="start" control={<Switch color="primary"
                    checked={s_usersRights.find(el => el.userId === uid) && s_usersRights.find(el => el.userId === uid).isContractManager}
                    onChange={() => handleChangeRights('isContractManager')} />} label="Contracts Manager" />

            </Stack>
        </Paper>
    )
}


export default UsersRightsPage;