


export function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
}

export function formatDate(date) {
    if (date)
        return (
            [
                date.getFullYear(),
                padTo2Digits(date.getMonth() + 1),
                padTo2Digits(date.getDate()),
            ].join('-') +
            ' ' +
            [
                padTo2Digits(date.getHours()),
                padTo2Digits(date.getMinutes()),
                padTo2Digits(date.getSeconds()),
            ].join(':')
        ); else return null;
}


export function formatDateWithoutHours(date) {
    if (date)
        return (
            [
                date.getFullYear(),
                padTo2Digits(date.getMonth() + 1),
                padTo2Digits(date.getDate()),
            ].join('-')
        ); else return null;
}


export function formatDateWithoutHoursUKFormat(date) {
    if (date)
        return (
            [
                padTo2Digits(date.getDate()),
                padTo2Digits(date.getMonth() + 1),
                date.getFullYear(),
            ].join('/')
        ); else return null;
}


export function formatDateWithoutHoursUKFormatDash(date) {
    if (date)
        return (
            [
                padTo2Digits(date.getDate()),
                padTo2Digits(date.getMonth() + 1),
                date.getFullYear(),
            ].join('-')
        ); else return null;
}

export function formatHoursMinutes(date) {
    if (date)
        return (
            [
                padTo2Digits(date.getHours()),
                padTo2Digits(date.getMinutes())
            ].join(':')
        ); else return null;
}
