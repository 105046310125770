import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { putRequest, transportRequestURL } from "../../../db/apiHandler";
import { editSelectedRequisition } from "../../../store/transportRequisitionSlice";
import { SelectInput } from "../../../utils/SelectInput";



const ChangeStatusDialog = ({ open, setOpen, entries, setEntries, setSuccess, setSuccessMessage }) => {
    const dispatch = useDispatch();
    const [value, setValue] = useState('');
    const options = ['PICKED', 'PART PICKED', 'NOT PICKED']

    const handleSelectChanged = (name, value) => {
        setValue(value);
    }

    const handleClose = () => {
        setOpen(false);
        setValue('');
    };

    const handleSave = async () => {
        let updateError = false;
        entries.forEach(async element => {
            let updateError = false;
            let copy = { ...element };
            copy.status = value;
            const response = await putRequest(`${transportRequestURL}/editRequest/${copy.id}`, copy)
                .catch(error => {
                    updateError = true;
                    console.error(error);
                });

            if (updateError) {
            } else {
                dispatch(editSelectedRequisition(response.response));
            }
        });
        if (updateError) {
            console.error('error')
        }
        setValue('');
        setOpen(false);
    }

    return (
        <div>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle sx={{
                    backgroundColor: 'primary.main',
                    color: 'primary.contrastText', fontWeight: 'normal'
                }} variant='contained'>Change Status</DialogTitle>
                <DialogContent sx={{ textAlign: 'center' }}>
                    <Typography>
                        The following action will change the status of all selected requisitions.
                    </Typography>
                    <SelectInput name='status' label='Status *'
                        value={value} options={options} handleChange={handleSelectChanged} />
                </DialogContent>
                <DialogActions sx={{ display: "flex", flexDirection: "row", alignSelf: "center" }}>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleSave} disabled={value === ''}>SAVE</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}


export default ChangeStatusDialog;