import React from 'react'

import { useEffect, useState } from "react";
import { Droppable } from "react-beautiful-dnd";

//There are problems using the default Droppable from react-beautiful-dnd when using react in strict mode.
//This Component resolves the issue, Use this instead of Droppble
const DroppableStrict = ({ children, ...props }) => {
    const [enabled, setEnabled] = useState(false);
    useEffect(() => {
        const animation = requestAnimationFrame(() => setEnabled(true));
        return () => {
            cancelAnimationFrame(animation);
            setEnabled(false);
        };
    }, []);
    if (!enabled) {
        return null;
    }
    return <Droppable {...props}>{children}</Droppable>;
};


export default DroppableStrict