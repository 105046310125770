
import { createSlice } from '@reduxjs/toolkit';

export const userInfoSlice = createSlice({

    name: 'user',
    initialState: {
        userDetails: null,
        users: null
    },
    reducers: {

        setUserDetails: (state, action) => {
            state.userDetails = action.payload
        },

        clearUserDetails: (state, action) => {
            state.userDetails = null;
        },
        setUsers: (state, action) => {
            state.users = action.payload;
          },
    },
});

export const { setUserDetails, clearUserDetails, setUsers } = userInfoSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const userDetails = state => state.user.userDetails;

export const users = (state) => state.user.users;

export default userInfoSlice.reducer;
