
import { createSlice } from '@reduxjs/toolkit';

export const addressSlice = createSlice({

    name: 'address',
    initialState: {

        addresses: null,
        selectedAddress: null,
        customerAddresses: null

    },
    reducers: {

        setAddresses: (state, action) => {
            state.addresses = action.payload
        },

        setSelectedAddress: (state, action) => {
            state.selectedAddress = action.payload
        },
        addAddress: (state, action) => {
            if (state.addresses) {
                state.addresses = [...state.addresses, action.payload.response];
                let addresses = [...state.addresses];
                const foundIndex = addresses.findIndex(el => el.id === action.payload.response.id);
                state.selectedAddress = addresses[foundIndex];
              } else {
                state.addresses = [];
                state.addresses.push(action.payload.response);
              } 
        },
        editAddress: (state, action) => {
            let addresses = [...state.addresses];
            const foundIndex = addresses.findIndex(el => el.id === action.payload.response.id);
            addresses[foundIndex] = action.payload.response;
            state.addresses = addresses;
            state.selectedAddress = action.payload.response;
        },
        deleteSelectedAddress: (state, action) => {
            state.selectedAddress = null;
            state.addresses = state.addresses.filter(el => el.id !== Number(action.payload))
        },

        setCustomerAddresses: (state, action) => {
            state.customerAddresses = action.payload
        },

    },
});

export const { setAddresses, setSelectedAddress, addAddress, editAddress, deleteSelectedAddress, setCustomerAddresses } = addressSlice.actions;

export const addresses = state => state.address.addresses;

export const selectedAddress = (state) => state.address.selectedAddress;

export const customerAddresses = (state) => state.address.customerAddresses;

export default addressSlice.reducer;
