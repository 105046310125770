import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fleetURL, postRequest, putRequest } from "../../db/apiHandler";
import { addDriver, editDriver, vehicles } from "../../store/fleetSlice";
import { SelectInput } from "../../utils/SelectInput";


const DriverFormDialog = ({ open, setOpen, entry, setEntry, setSuccess, setSuccessMessage }) => {
  const dispatch = useDispatch();

  const [canSave, setCanSave] = useState(false);
  const [primaryVehicleOptions, setPrimaryVehicleOptions] = useState([]);
  const [formData, setFormData] = useState({
    id: null,
    role: 'DRIVER',
    licenceType: '',
    depot: '',
    primaryVehicle: '',
    shift: ''
  });

  const s_vehicles = useSelector(vehicles);
  const licenceTypeOptions = ['B', 'C1', 'C2'];
  const depotOptions = ['Barnsley', 'London'];


  const unique = (value, index, self) => {
    return self.indexOf(value) === index
  }
  useEffect(() => {
    let vehicles = s_vehicles.map(el => el.registration);
    setPrimaryVehicleOptions(vehicles.filter(unique));
  }, [s_vehicles])



  useEffect(() => {
    if (formData.licenceType === '' || (formData.licenceType.length > 0 && formData.licenceType.replace(/\s/g, '').length === 0) ||
      formData.depot === '' || (formData.depot.length > 0 && formData.depot.replace(/\s/g, '').length === 0) ||
      formData.primaryVehicle === '' || (formData.primaryVehicle.length > 0 && formData.primaryVehicle.replace(/\s/g, '').length === 0) ||
      formData.shift === '' || (formData.shift.length > 0 && formData.shift.replace(/\s/g, '').length === 0)) {
      setCanSave(false);
    } else {
      setCanSave(true);
    }
  }, [formData])


  useEffect(() => {
    if (entry != null && open) {
      setFormData(entry);
      if (formData.licenceType === '' || (formData.licenceType.length > 0 && formData.licenceType.replace(/\s/g, '').length === 0) ||
        formData.depot === '' || (formData.depot.length > 0 && formData.depot.replace(/\s/g, '').length === 0) ||
        formData.primaryVehicle === '' || (formData.primaryVehicle.length > 0 && formData.primaryVehicle.replace(/\s/g, '').length === 0) ||
        formData.shift === '' || (formData.shift.length > 0 && formData.shift.replace(/\s/g, '').length === 0)) {
        setCanSave(false);
      } else {
        setCanSave(true);
      }
    } else {
      setFormData({
        id: null,
        role: 'DRIVER',
        licenceType: '',
        depot: '',
        primaryVehicle: '',
        shift: ''
      })
    }
  }, [entry, open])


  const handleClose = () => {
    setOpen(false);
  };

  const handleSelectChanged = (name, value) => {
    setFormData({ ...formData, [name]: value })
  }


  const handleTextFieldChange = (e) => {
    const name = e.target.getAttribute("name");
    const value = e.target.value;
    setFormData({ ...formData, [name]: value })
  }

  const handleAutocompleteChanged = (e, value) => {
    setFormData({ ...formData, primaryVehicle: value });
  }


  const handleSave = async () => {
    if (formData.id) {
      const response = await putRequest(`${fleetURL}/updateDriver/${formData.id}`, formData)
        .catch((error) => {
          console.error('error', error)
        });
      if (response.success) {
        dispatch(editDriver(response));
        setOpen(false);
        setSuccess(true);
        setSuccessMessage("Driver successfully updated.")
      } else {
        setSuccess(false);
      }
    } else {
      const response = await postRequest(`${fleetURL}/createDriver`, formData)
        .catch((error) => {
          console.error('error', error)
        });
      if (response.success) {
        dispatch(addDriver(response));
        setOpen(false);
        setSuccess(true);
        setSuccessMessage("Driver successfully added.")
      } else {
        setSuccess(false);
      }
    }

  }

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle sx={{
          backgroundColor: 'primary.main',
          color: 'primary.contrastText', fontWeight: 'normal'
        }} variant='contained'>{entry ? 'Edit Driver' : 'Create Driver'}</DialogTitle>
        <DialogContent>
          <Stack spacing={2} sx={{ display: "flex", flexDirection: "column", alignItems: "left" }}>
                <TextField required autoComplete='off' variant='standard' size='small' name='name' label='Name' sx={{ marginTop: 1 }} value={formData && formData.name ? formData.name : ''} onChange={handleTextFieldChange} />
                <SelectInput name='licenceType' label='Licence Type *'
                  value={formData.licenceType} options={licenceTypeOptions} handleChange={handleSelectChanged} />
                <SelectInput name='depot' label='Depot *'
                  value={formData.depot} options={depotOptions} handleChange={handleSelectChanged} />
                <Autocomplete
                  name='primaryVehicle'
                  key="primaryVehicle"
                  required
                  disablePortal
                  freeSolo
                  size='small'
                  options={primaryVehicleOptions ? primaryVehicleOptions : []}
                  sx={{ width: 250, marginTop: 2 }}
                  value={formData.primaryVehicle}
                  onChange={handleAutocompleteChanged}
                  renderInput={(params) => <TextField {...params} label="Primary Vehicle Registration Number" />
                  }
                />
                <TextField required autoComplete='off' variant='standard' size='small' name='shift' label='Shift' sx={{ marginTop: 1 }} value={formData && formData.shift ? formData.shift : ''} onChange={handleTextFieldChange} />

          </Stack>
        </DialogContent>
        <DialogActions sx={{ display: "flex", flexDirection: "row", alignSelf: "center" }}>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSave} disabled={!canSave}>SAVE</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default DriverFormDialog;