import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { appVersion } from "../../store/appSlice";
import { useSelector } from "react-redux";

const VersionConflictDialog = () => {

    const s_appVersion = useSelector(appVersion);

    const handleCloseConfirm = () => {
        // setOpen(false);
        window.location.reload();
    }

    return (
        <div>
            <Dialog
                open={s_appVersion === 'CONFLICT'}
                fullWidth maxWidth="sm"
            >
                <DialogTitle>
                    APP UPDATE
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        A new version of the app has been released. Please refresh the browser to continue using the Transport Tracker.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseConfirm} autoFocus>
                        REFRESH
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default VersionConflictDialog;