import { Button, Dialog, DialogActions, DialogContent, DialogTitle, List, ListItem, ListItemText, Stack } from '@mui/material'
import { getDownloadURL, getStorage, ref } from 'firebase/storage';
import React, { useState } from 'react'

const FirebasePODsDialog = ({ id, fileNames, setFileNames, open, setOpen }) => {

    const [selectedFile, setSelectedFile] = useState(null);

    const downloadFile = async () => {
        const storage = getStorage();
        let directoryPath = `fitoutuk/transport/${id}/POD`;
        let filePath = '';
        filePath = directoryPath + '/' + selectedFile;
        let fileRef = null;
        fileRef = ref(storage, filePath);
        const downloadURL = await getDownloadURL(fileRef);
        window.open(downloadURL, '_blank').focus();
    }

    const handleCloseDialog = () => {
        setOpen(false);
        setFileNames(null);
        setSelectedFile(null);
    }
    return (
        <div>
            <Dialog open={open} fullWidth maxWidth="sm">
                <DialogTitle>
                    Multiple POD Files Found
                </DialogTitle>
                <DialogContent>
                    <Stack sx={{ p: 1 }} spacing={1}>
                        <List>
                            {fileNames.map((el, index) => (
                                <React.Fragment key={index}>
                                    <ListItem
                                        key={index}
                                        disablePadding
                                        sx={{
                                            backgroundColor: selectedFile && selectedFile === el && 'primary.main',
                                            color: selectedFile && selectedFile === el && 'primary.contrastText',
                                            p: 1
                                        }}
                                    >
                                        <ListItemText id={index} primary={el} onClick={() => setSelectedFile(el)} />
                                    </ListItem>
                                </React.Fragment>
                            ))}
                        </List>
                    </Stack>


                </DialogContent>
                <DialogActions>
                    <Button onClick={downloadFile} variant='contained'>OPEN</Button>
                    <Button onClick={handleCloseDialog} variant='outlined'>Close</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default FirebasePODsDialog
