// import firebase from 'firebase/compat/app';
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";

 
 // Your web app's Firebase configuration
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional
  // var firebaseConfig = {

  //   //tcc.webapp
  //   //TCC
  //   apiKey: "AIzaSyDmmbtobrT0TGeytCSzgzbKppFlVbx-5qk",
  //   authDomain: "tcc-webapp-5505c.firebaseapp.com",
  //   projectId: "tcc-webapp-5505c",
  //   storageBucket: "tcc-webapp-5505c.appspot.com",
  //   messagingSenderId: "11908757504",
  //   appId: "1:11908757504:web:86fa3518590c93afbc40e5",
  //   measurementId: "G-FN50KLM6V6"
  // };

  // var firebaseConfig = {
  //   //fitoutukapps@gmail.com
  //   //FitOutUK
  //   apiKey: "AIzaSyAepL8rQNRrj687xiwMFRUmIktfAf4LNek",
  //   authDomain: "fitoutuk-3c952.firebaseapp.com",
  //   projectId: "fitoutuk-3c952",
  //   storageBucket: "fitoutuk-3c952.appspot.com",
  //   messagingSenderId: "241371799805",
  //   appId: "1:241371799805:web:444eb8a75581a5f5199e94",
  //   measurementId: "G-4VME2B577B"
  // };


  var firebaseConfig = {
    apiKey: "AIzaSyAepL8rQNRrj687xiwMFRUmIktfAf4LNek",
    authDomain: "fitoutuk-3c952.firebaseapp.com",
    projectId: "fitoutuk-3c952",
    storageBucket: "fitoutuk-3c952.appspot.com",
    messagingSenderId: "241371799805",
    appId: "1:241371799805:web:444eb8a75581a5f5199e94",
    measurementId: "G-4VME2B577B"
  };
  

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const analytics = getAnalytics(app)


  // const fire = initializeApp(firebaseConfig);
  // // Initialize Firebase
  // // const fire = firebase.initializeApp(firebaseConfig);
  // // firebase.analytics();

  // export default fire;