import { FormControl, InputLabel, MenuItem, Select } from "@mui/material"

export const SelectInput = ({ label, name, value, options, handleChange, readOnly, variant, width, noneOption = false }) => {
    return (
        <FormControl sx={{ width: width ? width : 250, marginTop: 2 }} size='small' variant={variant}>
            <InputLabel id={`${label}_Label`} >{label}</InputLabel>
            <Select
                labelId={`${label}_Label`}
                id={`${label}`}
                value={value}
                label={label}
                readOnly={readOnly}
                onChange={(e) => { handleChange(name, e.target.value) }}
                fullWidth
            >
                {noneOption &&
                    <MenuItem value="">
                        <em>None</em>
                    </MenuItem>}
                {options.map((option, index) => (
                    <MenuItem key={index} value={option}>{option}</MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}