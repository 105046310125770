import { Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from "@mui/material";
import { useSelector } from "react-redux";

import { users } from "../../store/userSlice";
import { NoWrapType } from "../../utils/NoWrapType";


const UserRightsTable = ({ list, page, rowsPerPage, setRowsPerPage, setPage, selectedRow, setSelectedRow }) => {

    const s_users = useSelector(users);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const selectRow = (row) => {
        if (selectedRow && selectedRow.id === row.id) {
            setSelectedRow(null);
        } else {
            setSelectedRow(row);
        }
    }

    return (
        <TableContainer sx={{ overflow: 'hidden' }}>
            <Table size="small" aria-label="a dense table" sx={{ marginLeft: '10px', marginRight: '10px' }}>
                <TableHead sx={{
                    backgroundColor: 'white',
                    color: 'primary.text'
                }}>
                    <TableRow>
                        <TableCell sx={{ color: "inherit", width: 150, fontWeight: 'bold' }} align="left">Name</TableCell>
                        <TableCell sx={{ color: "inherit", width: 150, fontWeight: 'bold' }} align="left">Email</TableCell>
                        <TableCell sx={{ color: "inherit", width: 150, fontWeight: 'bold' }} align="left">UID</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {list && (rowsPerPage > 0
                        ? list.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        : list
                    ).map((row, index) => (
                        <TableRow
                            hover={selectedRow && selectedRow.id === row.id ? false : true}
                            onClick={() => { selectRow(row) }}
                            key={index}
                            sx={{
                                '&:last-child td, &:last-child th': { border: 0 },
                                cursor: selectedRow && selectedRow.id === row.id && 'pointer',
                                backgroundColor: selectedRow && selectedRow.id === row.id && /*color(row, 'clientId')*/ 'slategrey',
                                color: selectedRow && selectedRow.id === row.id && 'primary.contrastText',
                            }}
                        >
                            <TableCell sx={{ color: 'inherit', maxWidth: 200 }} align="left"><NoWrapType text={row.username} /></TableCell>
                            <TableCell sx={{ color: 'inherit', maxWidth: 200 }} align="left"><NoWrapType text={row.email} /></TableCell>
                            <TableCell sx={{ color: 'inherit', maxWidth: 200 }} align="left"><NoWrapType text={row.userId} /></TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <TablePagination
                component="div"
                count={list ? list.length : -1}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </TableContainer>

    );
}


export default UserRightsTable;