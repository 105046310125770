import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from "@mui/material";
import { NoWrapType } from "../../utils/NoWrapType";



const DriversTable = ({ list, selectedRow, setSelectedRow, page, rowsPerPage, setPage, setRowsPerPage }) => {
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <TableContainer component={Paper}>
            <Table size="small" aria-label="a dense table">
                <TableHead sx={{
                    backgroundColor: 'primary.main',
                    color: 'primary.contrastText'
                }}>
                    <TableRow>
                        <TableCell sx={{ color: "inherit", width: 150 }} align="left">Depot</TableCell>
                        <TableCell sx={{ color: "inherit", width: 200 }} align="left">Name</TableCell>
                        <TableCell sx={{ color: "inherit", width: 100 }} align="left">Licence Type</TableCell>
                        <TableCell sx={{ color: "inherit", width: 200 }} align="left">Primary Vehicle</TableCell>
                        <TableCell sx={{ color: "inherit", width: 150 }} align="left">Shift</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {list && (rowsPerPage > 0
                        ? list.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        : list
                    ).map((row, index) => (
                        <TableRow
                            hover={selectedRow && selectedRow.id === row.id ? false : true}
                            onClick={() => { setSelectedRow(row) }}
                            key={index}
                            sx={{
                                '&:last-child td, &:last-child th': { border: 0 },
                                cursor: selectedRow && selectedRow.id === row.id && 'pointer',
                                backgroundColor: selectedRow && selectedRow.id === row.id && /*color(row, 'clientId')*/ 'primary.main',
                                color: selectedRow && selectedRow.id === row.id && 'primary.contrastText',
                            }}
                        >
                            <TableCell sx={{ color: 'inherit', maxWidth: 200 }} align="left"><NoWrapType text={row.depot} /></TableCell>
                            <TableCell sx={{ color: 'inherit', maxWidth: 120 }} align="left"><NoWrapType text={row.name} /></TableCell>
                            <TableCell sx={{ color: 'inherit', maxWidth: 120 }} align="left"><NoWrapType text={row.licenceType} /></TableCell>
                            <TableCell sx={{ color: 'inherit', maxWidth: 120 }} align="left"><NoWrapType text={row.primaryVehicle} /></TableCell>
                            <TableCell sx={{ color: 'inherit', maxWidth: 120 }} align="left"><NoWrapType text={row.shift} /></TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <TablePagination
                component="div"
                count={list ? list.length : -1}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </TableContainer>

    );
}


export default DriversTable;