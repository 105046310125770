import { configureStore } from '@reduxjs/toolkit';
import transportRequisitionReducer from './store/transportRequisitionSlice';
import userReducer from './store/userSlice'
import partReducer from './store/partSlice'
import appReducer from './store/appSlice'
import addressReducer from './store/addressSlice'
import fleetReducer from './store/fleetSlice'
import { devMode } from './db/devMode';

export const store = configureStore({
  reducer: {
    transportRequisition: transportRequisitionReducer,
    user: userReducer,
    parts: partReducer,
    appInfo: appReducer,
    address: addressReducer,
    fleet: fleetReducer
  },
  devTools: devMode
});
