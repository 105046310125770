import { createTheme, ThemeProvider } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './App.css';
import { getAppVersion } from './app/db/apiHandler';
import LoginHandler from './app/login/LoginHandler';
import { appTheme, appVersion, setAppVersion } from './app/store/appSlice';
import VersionConflictDialog from './app/components/settings/VersionConflictDialog';

function App() {

  const dispatch = useDispatch();
  const s_appTheme = useSelector(appTheme);
  const s_appVersion = useSelector(appVersion);
  const theme = createTheme({ ...s_appTheme });

  useEffect(() => {
    loadAppVersion();
  }, [s_appTheme]);

  const loadAppVersion = async () => {
    const response = await getAppVersion()
      .catch((error) => {
        console.error('error', error)
      });
    if (response.success) {
      dispatch(setAppVersion(response.response.version));
    }
  }

  // if (!s_appVersion) {
  //   return null;
  // }
  return (
    <ThemeProvider theme={theme} >
      <VersionConflictDialog />
      <LoginHandler />
    </ThemeProvider>
  );
}

export default App;
