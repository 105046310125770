import { jsPDF } from "jspdf";
import { createPageTitleLogoLg } from "../generatedFiles/ssccQuote/pdfHeadersFooters/pdfHeaders"
import { createsubTitleText, createFieldArrY } from "../generatedFiles/ssccQuote/pdfText/pdfText"
import { formatDateWithoutHoursUKFormat, padTo2Digits } from '../utils/DateFormat'

import moment from 'moment'

const accentColor = '#119dda';
const fieldOffset = 5;
const logoSizeLg = { x: 40, y: 40 }
const logoSizeSm = { x: 20, y: 20 }
const includeFooter = true;

const pageSizes = [
    { id: 'A4-landscape', size: 'a4', orientation: 'landscape', width: 297, height: 210, gutter: 12.7 },
    { id: 'A4-portrait', size: 'a4', orientation: 'portrait', width: 210, height: 297, gutter: 12.7 }
]

let page = { id: 'A4-portrait', size: 'a4', orientation: 'portrait', width: 210, height: 297, gutter: 12.7 }

const contentStart = logoSizeSm.y + page.gutter + page.gutter


export const createPDFfile = (requisition) => {

    page = pageSizes[1];

    var doc = new jsPDF({ putOnlyUsedFonts: true, orientation: page.orientation });

    createPage(doc, page, requisition);
    // doc.addPage(page.size, page.orientation);
    // createPage(doc, page, requisition);

    const fileName = 'Despatch Note ' + formatDateWithoutHoursUKFormat(new Date(requisition.latestDelivery)) + ' ' + requisition.jobNo + ' ' + (requisition.customerName.indexOf(' - ') > -1 ? requisition.customerName.split(' - ')[1] : requisition.customerName);
    doc.save(fileName);
}


export const createMasterQuoteHeader = (doc, page, requisition) => {
    createPageTitleLogoLg(doc, page, requisition.action)


    //Sub Header -> Delivery note no + deliveryDate
    let onlyDate = new Date(requisition.latestDelivery);
    let onlyDateString = (onlyDate.getDate() <= 9 ? ('0' + onlyDate.getDate().toString()) : onlyDate.getDate().toString()) + '/' +
        (onlyDate.getMonth() <= 8 ? ('0' + (onlyDate.getMonth() + 1).toString()) : (onlyDate.getMonth() + 1).toString()) + '/' +
        onlyDate.getFullYear().toString();
    let onlyTimeString = '';
    if (moment(requisition.earliestDelivery).format('YYYY-MM-DD') === moment(requisition.latestDelivery).format('YYYY-MM-DD') &&
        moment(requisition.earliestDelivery).format('HH:mm') !== moment(requisition.latestDelivery).format('HH:mm')) {
        onlyTimeString = moment(requisition.earliestDelivery).format('HH:mm') + ' - ' + moment(requisition.latestDelivery).format('HH:mm')
    } else {
        onlyTimeString = padTo2Digits(onlyDate.getHours()) + ':' + padTo2Digits(onlyDate.getMinutes());
    }
    createsubTitleText(doc, "Delivery Note No: " + requisition.id, page.gutter, 26.5)
    createsubTitleText(doc, (requisition.action === 'Delivery' ? "Delivery " : "Collection ") + "Date: " + onlyDateString, page.gutter, 33)
    createsubTitleText(doc, (requisition.action === 'Delivery' ? "Delivery " : "Collection ") + "Time: " + onlyTimeString, page.gutter + 90, 33)


    //Sub Header -> Customer Details
    createsubTitleText(doc, "Customer Name: " + (requisition.customerName ? requisition.customerName : ''), page.gutter, 45)
    const customerNameFields = ["Job No: " + (requisition.jobNo ? requisition.jobNo : '')]
    createFieldArrY(doc, customerNameFields, { posX: page.gutter, posY: 51.5, offset: fieldOffset }, 'normal')


    //Sub Header -> Collect/Deliver from and to details
    createsubTitleText(doc, "Collect from", page.gutter, 60)
    const fromFields = ["Address: " + requisition.addressFrom + ' ' + (requisition.collectionPostcode ? requisition.collectionPostcode : ''),
    "Contact: " + (requisition.collectionContact ? requisition.collectionContact : ''),
    "Contact Phone Number: " + (requisition.collectionContactNumber ? requisition.collectionContactNumber : '')];
    let posYfrom = createFieldArrY(doc, fromFields, { posX: page.gutter, posY: 66.5, offset: fieldOffset }, 'normal', true)

    const deliverToX = 110
    createsubTitleText(doc, "Deliver to", deliverToX, 60);
    const toFields = ["Address: " + requisition.addressTo + ' ' + (requisition.deliveryPostcode ? requisition.deliveryPostcode : ''),
    "Contact: " + (requisition.deliveryContact ? requisition.deliveryContact : ''),
    "Contact Phone Number: " + (requisition.deliveryContactNumber ? requisition.deliveryContactNumber : '')];
    let posYto = createFieldArrY(doc, toFields, { posX: deliverToX, posY: 66.5, offset: fieldOffset }, 'normal', true)


    const lineDimY = page.gutter + logoSizeLg.y + page.gutter
    doc.setLineWidth(0.2);
    doc.setDrawColor(accentColor); // draw red lines
    doc.line(page.gutter, posYfrom > posYto ? posYfrom : posYto, page.width - page.gutter, posYfrom > posYto ? posYfrom : posYto)    // horizontal line

    return posYfrom > posYto ? posYfrom : posYto;
}


const createPage = (doc, page, requisition) => {
    let posYHeader = createMasterQuoteHeader(doc, page, requisition);

    posYHeader = posYHeader + 10;
    createsubTitleText(doc, requisition.action === 'Delivery' ? "No. of pallets delivered: " : "No. of pallets collected: " + requisition.qtyPallets, page.gutter, posYHeader)
    createsubTitleText(doc, requisition.action === 'Delivery' ? "No. of cages delivered: " : "No. of cages collected: " + requisition.qtyCages, 110, posYHeader);

    posYHeader = posYHeader + 8;

    createsubTitleText(doc, "Description:", page.gutter, posYHeader)
    let posY = createFieldArrY(doc, [requisition.description], { posX: page.gutter, posY: posYHeader + 6.5, offset: 4 }, 'normal', false, true, page)

    createsubTitleText(doc, "Special Instructions:", page.gutter, posY + 2)
    createFieldArrY(doc, [requisition.specialInstructions], { posX: page.gutter, posY: posY + 6.5, offset: 4 }, 'normal', false, true, page)

    // if (requisition.description.length > 0) {
    //     createsubTitleText(doc, "Special Instructions:", page.gutter, posY + posYHeader)
    //     createFieldArrY(doc, [requisition.specialInstructions], { posX: page.gutter, posY: posY + posYHeader + 6, offset: fieldOffset }, 'normal', false, true, page)

    // } else {
    //     }
    // quoteOverviewTable(doc, page, includeFooter, quoteInfo, type)

    // switch (type) {

    //     case "PO": createQuotePagesPick(doc, quoteInfo, type); break;
    //     case "CPAR": createQuotePagesPercentage(doc, quoteInfo, type); break;
    //     case "ALL": createQuotePagesPercentage(doc, quoteInfo, type); break;

    //     default: break;
    // }

    // createQuotePagesPick(doc, quoteInfo, type);

    createsubTitleText(doc, "Print Name: ", page.gutter, 250)
    createsubTitleText(doc, "......................................", page.gutter, 260)

    createsubTitleText(doc, "Signed: ", 60, 250)
    createsubTitleText(doc, "......................................", 60, 260)

    createsubTitleText(doc, "Date: ", 110, 250)
    createsubTitleText(doc, "......................................", 110, 260)

    createsubTitleText(doc, "Time: ", 160, 250)
    createsubTitleText(doc, ".................................", 160, 260)
    // createFooters(doc, page)

    createsubTitleText(doc, "All damages/shortages must be noted on delivery note", 50, 270)
    createFieldArrY(doc, ["PRL Logistics Ltd, Abbey Road, Park Royal, London, NW10 7SJ, Tel 0208 963 6900, Fax 0208 963 6901"], { posX: 25, posY: 275, offset: fieldOffset }, 'normal')
}


