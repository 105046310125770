import DeleteIcon from '@mui/icons-material/Delete';
import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, IconButton, List, ListItem, ListItemIcon, ListItemText, Paper, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { fleetURL, postRequest, putRequest } from "../../db/apiHandler";
import { addVehicle, editDriver, editVehicle } from "../../store/fleetSlice";
import { formatDate } from "../../utils/DateFormat";
import { SelectInput } from "../../utils/SelectInput";


const VehicleFormDialog = ({ open, setOpen, entry, setSuccess, setSuccessMessage, drivers, tabIndex }) => {
  const dispatch = useDispatch();
  const [canSave, setCanSave] = useState(false);
  const [formData, setFormData] = useState({
    id: null,
    vehicleGroup: '',
    depot: '',
    make: '',
    type: '',
    registration: '',
    serialId: '',
    moffettTrailer: '',
    moffett: '',
    phoneNumber: ''
  });

  const vehicleGroupOptions = ['HGV', 'VAN'];
  const depotOptions = ['Barnsley', 'London', 'Spare'];

  const [selectedDrivers, setSelectedDrivers] = useState([]);
  const driverNameOptions = entry && entry.registration ? drivers.filter(el => el.primaryVehicle !== entry.registration).map(el => el.name) : drivers.map(el => el.name);
  const [searchField, setSearchField] = useState('');

  useEffect(() => {
    let list = [];
    if (entry) {
      if (entry.registration !== null && entry.registration !== '') {
        list = drivers.filter(el => el.primaryVehicle === entry.registration);
      }
    }
    setSelectedDrivers(list);
  }, [drivers, entry])


  useEffect(() => {
    if (formData.vehicleGroup === '' || (formData.vehicleGroup.length > 0 && formData.vehicleGroup.replace(/\s/g, '').length === 0) ||
      formData.depot === '' || (formData.depot.length > 0 && formData.depot.replace(/\s/g, '').length === 0) ||
      formData.make === '' || (formData.make.length > 0 && formData.make.replace(/\s/g, '').length === 0) ||
      formData.type === '' || (formData.type.length > 0 && formData.type.replace(/\s/g, '').length === 0) ||
      formData.registration === '' || (formData.registration.length > 0 && formData.registration.replace(/\s/g, '').length === 0) ||
      formData.serialId === '' || (formData.serialId.length > 0 && formData.serialId.replace(/\s/g, '').length === 0) ||
      formData.moffettTrailer === '' || (formData.moffettTrailer.length > 0 && formData.moffettTrailer.replace(/\s/g, '').length === 0) ||
      formData.moffett === '' || (formData.moffett.length > 0 && formData.moffett.replace(/\s/g, '').length === 0) ||
      formData.phoneNumber === '' || (formData.phoneNumber.length > 0 && formData.phoneNumber.replace(/\s/g, '').length === 0)) {
      setCanSave(false);
    } else {
      setCanSave(true);
    }
  }, [formData])


  useEffect(() => {
    if (entry != null && open) {
      setFormData(entry);
      if (formData.vehicleGroup === '' || (formData.vehicleGroup.length > 0 && formData.vehicleGroup.replace(/\s/g, '').length === 0) ||
        formData.depot === '' || (formData.depot.length > 0 && formData.depot.replace(/\s/g, '').length === 0) ||
        formData.make === '' || (formData.make.length > 0 && formData.make.replace(/\s/g, '').length === 0) ||
        formData.type === '' || (formData.type.length > 0 && formData.type.replace(/\s/g, '').length === 0) ||
        formData.registration === '' || (formData.registration.length > 0 && formData.registration.replace(/\s/g, '').length === 0) ||
        formData.serialId === '' || (formData.serialId.length > 0 && formData.serialId.replace(/\s/g, '').length === 0) ||
        formData.moffettTrailer === '' || (formData.moffettTrailer.length > 0 && formData.moffettTrailer.replace(/\s/g, '').length === 0) ||
        formData.moffett === '' || (formData.moffett.length > 0 && formData.moffett.replace(/\s/g, '').length === 0)) {
        setCanSave(false);
      } else {
        setCanSave(true);
      }
    } else {
      setFormData({
        id: null,
        vehicleGroup: '',
        depot: '',
        make: '',
        type: '',
        registration: '',
        serialId: '',
        moffettTrailer: '',
        moffett: '',
        phoneNumber: ''
      })
    }
  }, [entry, open])


  const handleClose = () => {
    setOpen(false);
  };

  const handleSelectChanged = (name, value) => {
    setFormData({ ...formData, [name]: value })
  }

  const handleTextFieldChange = (e) => {
    const name = e.target.getAttribute("name");
    const value = e.target.value;
    setFormData({ ...formData, [name]: value })
  }

  const handleDateChanged = (newDate) => {
    setFormData({ ...formData, availableFrom: formatDate(newDate) })
  }

  const handleAutocompleteChanged = async (e, value) => {
    if (value && value !== '') {
      setSearchField('');
      let driver = { ...drivers.find(el => el.name === value) };
      driver.primaryVehicle = formData.registration;
      const response = await putRequest(`${fleetURL}/updateDriver/${driver.id}`, driver)
        .catch((error) => {
          console.error('error', error)
        });
      if (response.success) {
        dispatch(editDriver(response));
        setSelectedDrivers([...selectedDrivers, drivers.find(el => el.name === value)]);
      }
    }
  }


  const handleSave = async () => {
    if (formData.id) {
      const response = await putRequest(`${fleetURL}/updateVehicle/${formData.id}`, formData)
        .catch((error) => {
          console.error('error', error)
        });
      if (response.success) {
        dispatch(editVehicle(response));
        setOpen(false);
        setSuccess(true);
        setSuccessMessage("Vehicle successfully updated.")
      } else {
        setSuccess(false);
      }
    } else {
      const response = await postRequest(`${fleetURL}/createVehicle`, formData)
        .catch((error) => {
          console.error('error', error)
        });
      if (response.success) {
        dispatch(addVehicle(response));
        setOpen(false);
        setSuccess(true);
        setSuccessMessage("Vehicle successfully added.")
      } else {
        setSuccess(false);
      }
    }

  }

  const handleDeleteDriver = async (id) => {
    let driver = { ...drivers.find(el => el.id === id) };
    driver.primaryVehicle = '';
    const response = await putRequest(`${fleetURL}/updateDriver/${driver.id}`, driver)
      .catch((error) => {
        console.error('error', error)
      });
    if (response.success) {
      dispatch(editDriver(response));
      setSelectedDrivers(selectedDrivers.filter(el => el.id !== id));
    }
  }

  return (
    <div>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
        <DialogTitle sx={{
          backgroundColor: 'primary.main',
          color: 'primary.contrastText', fontWeight: 'normal'
        }} variant='contained'>{entry ? 'Edit Vehicle' : 'Create Vehicle'}</DialogTitle>
        <DialogContent>

          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={6}>
              <Paper sx={{ p: 2 }}>

                <Divider sx={{ color: 'primary.main', fontWeight: 'bold', my: 2 }}>Vehicle Details</Divider>
                {tabIndex === '2' ?
                  <SelectInput name='vehicleGroup' label='Vehicle Group *'
                    value={formData.vehicleGroup} options={vehicleGroupOptions} handleChange={handleSelectChanged} /> :
                  <TextField fullWidth autoComplete='off' variant='standard' size='small' name='vehicleGroup' label='Vehicle Group' sx={{ marginTop: 1 }} value={formData && formData.vehicleGroup ? formData.vehicleGroup : ''} inputProps={{ readOnly: true }} />
                }
                {tabIndex === '2' ?
                  <SelectInput name='depot' label='Depot *'
                    value={formData.depot} options={depotOptions} handleChange={handleSelectChanged} /> :
                  <TextField fullWidth autoComplete='off' variant='standard' size='small' name='depot' label='Depot' sx={{ marginTop: 1 }} value={formData && formData.depot ? formData.depot : ''} inputProps={{ readOnly: true }} />
                }
                {tabIndex === '2' && <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker label='Available From *'
                    renderInput={(props) => <TextField sx={{ marginTop: 2 }}  {...props} />}
                    name='availableFrom'
                    value={formData.availableFrom || null}
                    onChange={handleDateChanged}
                    required
                    inputFormat='dd/MM/yyyy'
                  />
                </LocalizationProvider>}

                <TextField fullWidth required autoComplete='off' variant='standard' size='small' name='make' label='Make' sx={{ marginTop: 1 }} value={formData && formData.make ? formData.make : ''} onChange={handleTextFieldChange} inputProps={{ readOnly: tabIndex === '1' }} />
                <TextField fullWidth required autoComplete='off' variant='standard' size='small' name='type' label='Type' sx={{ marginTop: 1 }} value={formData && formData.type ? formData.type : ''} onChange={handleTextFieldChange} inputProps={{ readOnly: tabIndex === '1' }} />
                <TextField fullWidth required autoComplete='off' variant='standard' size='small' name='registration' label='Registration' sx={{ marginTop: 1 }} value={formData && formData.registration ? formData.registration : ''} onChange={handleTextFieldChange} inputProps={{ readOnly: tabIndex === '1' }} />
                <TextField fullWidth required autoComplete='off' variant='standard' size='small' name='serialId' label='Serial ID' sx={{ marginTop: 1 }} value={formData && formData.serialId ? formData.serialId : ''} onChange={handleTextFieldChange} inputProps={{ readOnly: tabIndex === '1' }} />
                <TextField fullWidth required autoComplete='off' variant='standard' size='small' name='moffettTrailer' label='Moffett Trailer' sx={{ marginTop: 1 }} value={formData && formData.moffettTrailer ? formData.moffettTrailer : ''} onChange={handleTextFieldChange} inputProps={{ readOnly: tabIndex === '1' }} />
                <TextField fullWidth required autoComplete='off' variant='standard' size='small' name='moffett' label='Moffett' sx={{ marginTop: 1 }} value={formData && formData.moffett ? formData.moffett : ''} onChange={handleTextFieldChange} inputProps={{ readOnly: tabIndex === '1' }} />
                {tabIndex === '2' && <TextField fullWidth autoComplete='off' variant='standard' size='small' name='phoneNumber' label='Phone Number' sx={{ marginTop: 1 }} value={formData && formData.phoneNumber ? formData.phoneNumber : ''} onChange={handleTextFieldChange} />}
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper sx={{ p: 2 }}>
                <Divider sx={{ color: 'primary.main', fontWeight: 'bold', my: 2 }}>Drivers</Divider>
                <Autocomplete
                  disabled={formData.registration === '' || formData.registration === null}
                  name='driverName'
                  key="driverName"
                  disablePortal
                  freeSolo
                  size='small'
                  options={driverNameOptions ? driverNameOptions : []}
                  sx={{ width: 300 }}
                  value={searchField}
                  onChange={handleAutocompleteChanged}
                  renderInput={(params) => <TextField {...params} label="Search driver..." />
                  }
                />
                <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                  {selectedDrivers && selectedDrivers.map((value) => (
                    <ListItem
                      key={value.id}
                      disableGutters
                    >
                      <ListItemText primary={value.name} />
                      <ListItemIcon>
                        <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteDriver(value.id)}>
                          <DeleteIcon />
                        </IconButton>
                      </ListItemIcon>
                    </ListItem>
                  ))}
                </List>
              </Paper>
            </Grid>
          </Grid>


        </DialogContent>
        <DialogActions sx={{ display: "flex", flexDirection: "row", alignSelf: "center" }}>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSave} disabled={!canSave}>SAVE</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default VehicleFormDialog;