// import { jsPDF } from "jspdf";
// import 'jspdf-autotable'
import moment from 'moment'
import fitoutLogo from '../../../assets/fitoutLogo.jpeg'
import prlLogo from '../../../assets/prl-logistics.png'
import { createFieldArrY, createTitleText, createsubTitleText } from '../pdfText/pdfText'


const logoSizeLg = { x: 30, y: 30 }
const logoSizeSm = { x: 20, y: 20 }

const accentColor = '#119dda';
const fieldOffset = 5;


const toFixed2 = (value) => {
    return `£${value.toFixed(2)}`;

}


const createDocHeader = (doc, page) => {
    const imgPosX = page.width - logoSizeLg.x - page.gutter
    // doc.addImage(ssccLogo, 'JPEG', imgPosX, page.gutter, logoSizeLg.x, logoSizeLg.y)

    createTitleText(doc, "Quotation", page.gutter, 18)

    createsubTitleText(doc, "Project Details", page.gutter, 26.5)

    const projectDetailFields = ["Location", "Type", "Date"]
    createFieldArrY(doc, projectDetailFields, { posX: page.gutter, posY: 33, offset: fieldOffset }, 'bold')

    const projectDetailFieldsV = ["Somewhere", "Express", "18/06/2021"]
    createFieldArrY(doc, projectDetailFieldsV, { posX: 30, posY: 33, offset: fieldOffset }, 'normal')

    createsubTitleText(doc, "Project Information", 78.5, 26.5)

    const projectInfoFields = ["Sainsbury's PM", "Call Off Submitted By"]
    createFieldArrY(doc, projectInfoFields, { posX: 78.5, posY: 33, offset: fieldOffset }, 'bold')

    const projectInfoFieldsV = ["John Doe", "Jane Doe"]
    createFieldArrY(doc, projectInfoFieldsV, { posX: 125, posY: 33, offset: fieldOffset }, 'normal')

    const lineDimY = page.gutter + logoSizeLg.y + page.gutter
    doc.setLineWidth(0.2);
    doc.setDrawColor(accentColor); // draw red lines
    doc.line(page.gutter, lineDimY, page.width - page.gutter, lineDimY)    // horizontal line

    return doc
}




export const createMasterQuoteHeader = (doc, page, quoteInfo) => {
    createPageTitleLogoLg(doc, page, `Quotation - ${quoteInfo.projectInfo.storeName} (${quoteInfo.projectInfo.storeID})`)

    //Sub Header -> PROJECT DETAILS
    createsubTitleText(doc, "Project Details", page.gutter, 26.5)

    const projectDetailFields = ["Date:", "Type:", "Address:"]
    createFieldArrY(doc, projectDetailFields, { posX: page.gutter, posY: 33, offset: fieldOffset }, 'bold')

    const address = [
        `${quoteInfo.projectInfo.storeName} (${quoteInfo.projectInfo.storeID})`,
        quoteInfo.projectInfo.storeInformation.address1,
        quoteInfo.projectInfo.storeInformation.address2,
        quoteInfo.projectInfo.storeInformation.address3,
        quoteInfo.projectInfo.storeInformation.postCode,
    ]

    const projectDetailFieldsV = [moment().format("ddd DD MMM YYYY").toString(), quoteInfo.projectInfo.storeInformation.type, [...address]]
    createFieldArrY(doc, projectDetailFieldsV, { posX: 30, posY: 33, offset: fieldOffset }, 'normal')



    const projectInfoX = 120
    createsubTitleText(doc, "Project Information", projectInfoX, 26.5)

    const projectInfoFields = ["Sainsbury's PM:", "Call Off Submitted By:"]
    createFieldArrY(doc, projectInfoFields, { posX: projectInfoX, posY: 33, offset: fieldOffset }, 'bold')

    const projectInfoFieldsV = [quoteInfo.projectInfo.projectManager.name, quoteInfo.projectInfo.createdBy]
    createFieldArrY(doc, projectInfoFieldsV, { posX: projectInfoX+40, posY: 33, offset: fieldOffset }, 'normal')

    const lineDimY = page.gutter + logoSizeLg.y + page.gutter
    doc.setLineWidth(0.2);
    doc.setDrawColor(accentColor); // draw red lines
    doc.line(page.gutter, lineDimY, page.width - page.gutter, lineDimY)    // horizontal line
}


//Header with both pick price and percentage price
export const quoteDeliveryHeader = (doc, page, fieldOffset, deliveryInfo, index, type) => {
    const phase = deliveryInfo.phase
    const totals = deliveryInfo.totals
    createPageHeaderSm(doc, page, `Deilvery ${index + 1} - ${deliveryInfo.phase.phaseTitle}`)

    const deliveryDetails = ["Dispatch Date:", "Required Date:", "Required Time:", "CPAR:"]
    createFieldArrY(doc, deliveryDetails, { posX: page.gutter, posY: 23, offset: fieldOffset }, 'bold')

    const deliveryDetailsV = [moment(phase.onSiteDate).subtract(1, "days").format("ddd DD MMM YYYY"), moment(phase.onSiteDate).format("ddd DD MMM YYYY"), phase.onSiteTimeD, phase.cparNumber]
    createFieldArrY(doc, deliveryDetailsV, { posX: page.gutter + 30, posY: 23, offset: fieldOffset }, 'normal')

    //const linePosX = 70
    //doc.setLineWidth(0.2);
    //doc.setDrawColor(accentColor); // draw red lines
    //doc.line(page.gutter + linePosX, 18, page.gutter + linePosX, 40)    // horizontal line


    let totalsLabel = ["Total:"];
    let totalsValue = [toFixed2(totals.totalPOPrice)];
    let grandTotalsLabels = ["GRAND TOTALS"];
    let grandTotalsValues = [""];


    switch (type) {
        case "Pick Price":
            totalsLabel.push("Total Pick:")
            totalsLabel.push("Grand Total:")
            totalsValue.push(toFixed2(totals.totalPOPickPrice))
            totalsValue.push(toFixed2(totals.grandTotalPOPick))
            //grandTotalsLabels.push("Grand Total")
            //grandTotalsValues = [toFixed2(totals.grandTotalPOPick)]
            break;

        case "Percentage Price":
            totalsLabel.push("Total Pick (5%):")
            totalsLabel.push("Grand Total:")
            totalsValue.push(toFixed2(totals.totalPOPickPercentage))
            totalsValue.push(toFixed2(totals.grandTotalPOPercentage))


            //grandTotalsLabels.push(toFixed2(totals.grandTotalPOPick))
            //grandTotalsValues = [toFixed2(totals.grandTotalPOPercentage)]
            break;

        default:
            break;
    }


    //createFieldArrY(doc, totalsLabel, { posX: page.gutter + 80, posY: 23, offset: fieldOffset }, 'bold')
    //createFieldArrY(doc, totalsValue, { posX: page.gutter + 110, posY: 23, offset: fieldOffset }, 'normal')
    //createFieldArrY(doc, grandTotalsLabels, { posX: page.gutter + 135, posY: 23, offset: fieldOffset }, 'bold')
    //createFieldArrY(doc, grandTotalsValues, { posX: page.gutter + 170, posY: 23, offset: fieldOffset }, 'normal')

}


//Header with both pick price and percentage price
export const quoteDeliveryHeaderA = (doc, page, fieldOffset, deliveryInfo, index) => {
    const phase = deliveryInfo.phase
    const totals = deliveryInfo.totals
    createPageHeaderSm(doc, page, `Deilvery ${index + 1} - ${deliveryInfo.phase.phaseTitle}`)

    const deliveryDetails = ["Dispatch Date:", "Required Date:", "Required Time:", "CPAR:"]
    createFieldArrY(doc, deliveryDetails, { posX: page.gutter, posY: 23, offset: fieldOffset }, 'bold')

    const deliveryDetailsV = [moment(phase.onSiteDate).subtract(1, "days").format("ddd DD MMM YYYY"), moment(phase.onSiteDate).format("ddd DD MMM YYYY"), phase.onSiteTimeD, phase.cparNumber]
    createFieldArrY(doc, deliveryDetailsV, { posX: page.gutter + 30, posY: 23, offset: fieldOffset }, 'normal')

    const linePosX = 70
    doc.setLineWidth(0.2);
    doc.setDrawColor(accentColor); // draw red lines
    doc.line(page.gutter + linePosX, 18, page.gutter + linePosX, 40)    // horizontal line


    const totalsLabel = ["Total:", "Total Pick:", "Total Pick (5%):"]
    createFieldArrY(doc, totalsLabel, { posX: page.gutter + 80, posY: 23, offset: fieldOffset }, 'bold')

    const totalsValue = [toFixed2(totals.totalPOPrice), toFixed2(totals.totalPOPickPrice), toFixed2(totals.totalPOPickPercentage)]
    createFieldArrY(doc, totalsValue, { posX: page.gutter + 110, posY: 23, offset: fieldOffset }, 'normal')

    const grandTotalsLabels = ["GRAND TOTALS", "Grand Total", "Grand Total (5%)"]
    createFieldArrY(doc, grandTotalsLabels, { posX: page.gutter + 135, posY: 23, offset: fieldOffset }, 'bold')

    const grandTotalsValues = ["", toFixed2(totals.grandTotalPOPick), toFixed2(totals.grandTotalPOPercentage)]
    createFieldArrY(doc, grandTotalsValues, { posX: page.gutter + 170, posY: 23, offset: fieldOffset }, 'normal')

}


//Header with both pick price ONLY
export const quoteDeliveryHeaderPick = (doc, page, fieldOffset, deliveryInfo, index) => {
    const phase = deliveryInfo.phase
    const totals = deliveryInfo.totals
    createPageHeaderSm(doc, page, `Deilvery ${index + 1} - ${deliveryInfo.phase.phaseTitle}`)

    const deliveryDetails = ["Dispatch Date:", "Required Date:", "Required Time:", "CPAR:"]
    createFieldArrY(doc, deliveryDetails, { posX: page.gutter, posY: 23, offset: fieldOffset }, 'bold')

    const deliveryDetailsV = [moment(phase.onSiteDate).subtract(1, "days").format("ddd DD MMM YYYY"), moment(phase.onSiteDate).format("ddd DD MMM YYYY"), phase.onSiteTimeD, phase.cparNumber]
    createFieldArrY(doc, deliveryDetailsV, { posX: page.gutter + 30, posY: 23, offset: fieldOffset }, 'normal')

    const linePosX = 70
    doc.setLineWidth(0.2);
    doc.setDrawColor(accentColor); // draw red lines
    doc.line(page.gutter + linePosX, 18, page.gutter + linePosX, 40)    // horizontal line


    const totalsLabel = ["Total:", "Total Pick:"]
    createFieldArrY(doc, totalsLabel, { posX: page.gutter + 80, posY: 23, offset: fieldOffset }, 'bold')

    const totalsValue = [toFixed2(totals.totalPOPrice), toFixed2(totals.totalPOPickPrice)]
    createFieldArrY(doc, totalsValue, { posX: page.gutter + 110, posY: 23, offset: fieldOffset }, 'normal')

    const grandTotalsLabels = ["GRAND TOTALS", "Grand Total"]
    createFieldArrY(doc, grandTotalsLabels, { posX: page.gutter + 135, posY: 23, offset: fieldOffset }, 'bold')

    const grandTotalsValues = ["", toFixed2(totals.grandTotalPOPick)]
    createFieldArrY(doc, grandTotalsValues, { posX: page.gutter + 170, posY: 23, offset: fieldOffset }, 'normal')

}

//Header with both pick price and percentage price
export const quoteDeliveryHeaderPercentage = (doc, page, fieldOffset, deliveryInfo, index) => {
    const phase = deliveryInfo.phase
    const totals = deliveryInfo.totals
    createPageHeaderSm(doc, page, `Deilvery ${index + 1} - ${deliveryInfo.phase.phaseTitle}`)

    const deliveryDetails = ["Dispatch Date:", "Required Date:", "Required Time:", "CPAR:"]
    createFieldArrY(doc, deliveryDetails, { posX: page.gutter, posY: 23, offset: fieldOffset }, 'bold')

    const deliveryDetailsV = [moment(phase.onSiteDate).subtract(1, "days").format("ddd DD MMM YYYY"), moment(phase.onSiteDate).format("ddd DD MMM YYYY"), phase.onSiteTimeD, phase.cparNumber]
    createFieldArrY(doc, deliveryDetailsV, { posX: page.gutter + 30, posY: 23, offset: fieldOffset }, 'normal')

    const linePosX = 70
    doc.setLineWidth(0.2);
    doc.setDrawColor(accentColor); // draw red lines
    doc.line(page.gutter + linePosX, 18, page.gutter + linePosX, 40)    // horizontal line


    const totalsLabel = ["Total:", "Total Pick (5%):"]
    createFieldArrY(doc, totalsLabel, { posX: page.gutter + 80, posY: 23, offset: fieldOffset }, 'bold')

    const totalsValue = [toFixed2(totals.totalPOPrice), toFixed2(totals.totalPOPickPercentage)]
    createFieldArrY(doc, totalsValue, { posX: page.gutter + 110, posY: 23, offset: fieldOffset }, 'normal')

    const grandTotalsLabels = ["GRAND TOTALS", "Grand Total (5%)"]
    createFieldArrY(doc, grandTotalsLabels, { posX: page.gutter + 135, posY: 23, offset: fieldOffset }, 'bold')

    const grandTotalsValues = ["", toFixed2(totals.grandTotalPOPercentage)]
    createFieldArrY(doc, grandTotalsValues, { posX: page.gutter + 170, posY: 23, offset: fieldOffset }, 'normal')

}






export const createPageTitleLogoLg = (doc, page, title) => {
    const imgPosX = page.width - logoSizeLg.x - page.gutter
    doc.addImage(prlLogo, 'PNG', imgPosX, page.gutter, logoSizeLg.x, logoSizeLg.y)

    createTitleText(doc, title, page.gutter, 18)
}



export const createPageHeaderSm = (doc, page, title) => {
    const imgPosX = page.width - logoSizeSm.x - page.gutter
    // doc.addImage(fitoutLogo, 'JPEG', imgPosX, page.gutter, logoSizeSm.x, logoSizeSm.y)
    createTitleText(doc, title, page.gutter, 18)

    const lineDimY = page.gutter + logoSizeSm.y + page.gutter
    doc.setLineWidth(0.2);
    doc.setDrawColor(accentColor); // draw red lines
    doc.line(page.gutter, lineDimY, page.width - page.gutter, lineDimY)    // horizontal line
}

