
import { createSlice } from '@reduxjs/toolkit';

export const fleetSlice = createSlice({

    name: 'fleet',
    initialState: {

        drivers: null,
        vehicles: null

    },
    reducers: {

        setDrivers: (state, action) => {
            state.drivers = action.payload
        }, 
        
        addDriver: (state, action) => {
            if (state.drivers) {
                state.drivers = [...state.drivers, action.payload.response];
              } else {
                state.drivers = [];
                state.drivers.push(action.payload.response);
              } 
        },
        editDriver: (state, action) => {
            let drivers = [...state.drivers];
            const foundIndex = drivers.findIndex(el => el.id === action.payload.response.id);
            drivers[foundIndex] = action.payload.response;
            state.drivers = drivers;
        },
        deleteSelectedDriver: (state, action) => {
            state.drivers = state.drivers.filter(el => el.id !== Number(action.payload))
        },


        setVehicles: (state, action) => {
            state.vehicles = action.payload
        }, 
        
        addVehicle: (state, action) => {
            if (state.vehicles) {
                state.vehicles = [...state.vehicles, action.payload.response];
              } else {
                state.vehicles = [];
                state.vehicles.push(action.payload.response);
              } 
        },
        editVehicle: (state, action) => {
            let vehicles = [...state.vehicles];
            const foundIndex = vehicles.findIndex(el => el.id === action.payload.response.id);
            vehicles[foundIndex] = action.payload.response;
            state.vehicles = vehicles;
        },
        deleteSelectedVehicle: (state, action) => {
            let vehicle =  state.vehicles.find(el => el.id === Number(action.payload));
            let drivers = state.drivers;
            drivers.forEach(el => {
                if(el.primaryVehicle === vehicle.registration)
                    el.primaryVehicle = '';
            });
            state.drivers = drivers;
            state.vehicles = state.vehicles.filter(el => el.id !== Number(action.payload))
        }

    },
});

export const { setDrivers, addDriver, editDriver, deleteSelectedDriver,
                setVehicles, addVehicle, editVehicle, deleteSelectedVehicle } = fleetSlice.actions;

export const drivers = state => state.fleet.drivers;
export const vehicles = state => state.fleet.vehicles;

export default fleetSlice.reducer;
