import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { NoWrapType } from "../../utils/NoWrapType";

const FleetTable = ({ vehiclesList, driversList, selectedRow, setSelectedRow, page, rowsPerPage, setPage, setRowsPerPage }) => {
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const getDrivers = (row) => {
        let drivers = driversList.filter(el => el.primaryVehicle === row.registration && row.registration !== '' ).map(el => el.name);
        let driversAsString = '';
        drivers.forEach(el => {
            driversAsString = driversAsString + el + ', ';
        });
        driversAsString = driversAsString.slice(0, -2);
        return driversAsString;
    }

    const isAnyDriverForDay = (row) => {
        let drivers  = driversList.filter(el => el.primaryVehicle === row.registration && row.registration !== '').filter(el => el.shift === 'Day');
        if(drivers.length > 0) {
            return true;
        }
        return false;
    }
    const isAnyDriverForNight = (row) => {
        let drivers  = driversList.filter(el => el.primaryVehicle === row.registration && row.registration !== '').filter(el => el.shift === 'Night');
        if(drivers.length > 0) {
            return true;
        }
        return false;
    }
    // 'Depot', 'Vehicle Group', 'Make', 'Type', 'Registration', 'Drivers', 'Day Driver?', 'Night Driver?', 'Serial ID', 'Moffett Trailer', 'Moffett'
    return (
        <TableContainer component={Paper}>
            <Table size="small" aria-label="a dense table">
                <TableHead sx={{
                    backgroundColor: 'primary.main',
                    color: 'primary.contrastText'
                }}>
                    <TableRow>
                        <TableCell sx={{ color: "inherit", width: 70 }} align="left">Depot</TableCell>
                        <TableCell sx={{ color: "inherit", width: 50 }} align="left">Vehicle Group</TableCell>
                        <TableCell sx={{ color: "inherit", width: 80 }} align="left">Make</TableCell>
                        <TableCell sx={{ color: "inherit", width: 70 }} align="left">Type</TableCell>
                        <TableCell sx={{ color: "inherit", width: 80 }} align="left">Registration</TableCell>
                        <TableCell sx={{ color: "inherit", width: 220 }} align="left">Drivers</TableCell>
                        <TableCell sx={{ color: "inherit", width: 50 }} align="left">Day Driver?</TableCell>
                        <TableCell sx={{ color: "inherit", width: 50 }} align="left">Night Driver?</TableCell>
                        <TableCell sx={{ color: "inherit", width: 80 }} align="left">Serial ID</TableCell>
                        <TableCell sx={{ color: "inherit", width: 160 }} align="left">Moffett Trailer</TableCell>
                        <TableCell sx={{ color: "inherit", width: 150 }} align="left">Moffett</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {vehiclesList && (rowsPerPage > 0
                        ? vehiclesList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        : vehiclesList
                    ).map((row, index) => (
                        <TableRow
                            hover={selectedRow && selectedRow.id === row.id ? false : true}
                            onClick={() => { setSelectedRow(row) }}
                            key={index}
                            sx={{
                                '&:last-child td, &:last-child th': { border: 0 },
                                cursor: selectedRow && selectedRow.id === row.id && 'pointer',
                                backgroundColor: selectedRow && selectedRow.id === row.id && /*color(row, 'clientId')*/ 'primary.main',
                                color: selectedRow && selectedRow.id === row.id && 'primary.contrastText',
                            }}
                        >
                            <TableCell sx={{ color: 'inherit', maxWidth: 70 }} align="left"><NoWrapType text={row.depot} /></TableCell>
                            <TableCell sx={{ color: 'inherit', maxWidth: 50 }} align="left"><NoWrapType text={row.vehicleGroup} /></TableCell>
                            <TableCell sx={{ color: 'inherit', maxWidth: 80 }} align="left"><NoWrapType text={row.make} /></TableCell>
                            <TableCell sx={{ color: 'inherit', maxWidth: 70 }} align="left"><NoWrapType text={row.type} /></TableCell>
                            <TableCell sx={{ color: 'inherit', maxWidth: 70 }} align="left"><NoWrapType text={row.registration} /></TableCell>
                            <TableCell sx={{ color: 'inherit', maxWidth: 220 }} align="left"><NoWrapType text={getDrivers(row)} /></TableCell>
                            <TableCell sx={{ color: 'inherit', maxWidth: 50 }} align="left"><NoWrapType text={isAnyDriverForDay(row) ? <CheckIcon /> : <CloseIcon /> } /></TableCell>
                            <TableCell sx={{ color: 'inherit', maxWidth: 50 }} align="left"><NoWrapType text={isAnyDriverForNight(row) ? <CheckIcon /> : <CloseIcon /> } /></TableCell>
                            <TableCell sx={{ color: 'inherit', maxWidth: 80 }} align="left"><NoWrapType text={row.serialId} /></TableCell>
                            <TableCell sx={{ color: 'inherit', maxWidth: 100 }} align="left"><NoWrapType text={row.moffettTrailer} /></TableCell>
                            <TableCell sx={{ color: 'inherit', maxWidth: 120 }} align="left"><NoWrapType text={row.moffett} /></TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <TablePagination
                component="div"
                count={vehiclesList ? vehiclesList.length : -1}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </TableContainer>

    );
}


export default FleetTable;