import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import { apiURL, getRequest, postRequest, transportRequestURL } from '../db/apiHandler';
import { formatDateWithoutHoursUKFormat, formatDateWithoutHoursUKFormatDash, formatHoursMinutes } from '../utils/DateFormat';
import { phoneNumberPattern } from '../components/importTracker/ImportExportExcel';
// import { removeIllegalAndSpaces, removeIllegalExcel } from '../../../../helpers/textFunctions/textFunctions';



export const createParagonFile = async (list, shouldDownload = true, users, customerAddresses) => {
    return new Promise(async (resolve, reject) => {

        //CREATE FILE NAME
        let fileName = ``
        // fileName = removeIllegalAndSpaces(fileName)


        //CREATE EXCEL WORKBOOK
        let workbook = new ExcelJS.Workbook();

        //Set Document Properties
        const dateNow = new Date()
        workbook.creator = 'FitOutUK';
        workbook.lastModifiedBy = 'FitOutUK';
        workbook.created = dateNow;
        workbook.modified = dateNow;

        var today = new Date();
        var h = String(today.getHours()).padStart(2, '0');
        var mins = String(today.getMinutes()).padStart(2, '0');
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();

        fileName = 'ParagonCSVUpdate ' + dd + '-' + mm + '-' + yyyy + ' ' + h + '-' + mins + '.csv';

        resolve(createParagonCSVFile(workbook, list, fileName, users, customerAddresses));

    })
}

const removeCarriageReturns = (str) => {

    if (str) {
        str = str.replaceAll(',', ' ').replaceAll(';', ' ').replaceAll("'", ' ').replaceAll('"', '-');
        str = str.replaceAll('\r', ' ').replaceAll('\n', ' ').replaceAll('\f', ' ').replaceAll('\t', ' ');
        str = str.trim();
    }
    return str;
}

const createRow = (element, s_users, foundAddress) => {
    let dateNotified = new Date(element.dateNotified);
    let dateNotifiedString = formatDateWithoutHoursUKFormatDash(dateNotified);
    let deliveryDate = new Date(element.latestDelivery);
    let deliveryDateString = formatDateWithoutHoursUKFormatDash(deliveryDate);
    let deliveryHoursString = formatHoursMinutes(deliveryDate);;
    let user = s_users.find(el => el.uid === element.userId);
    return [
        ((element.jobNo ? removeCarriageReturns(element.jobNo?.toUpperCase()) : '') + (element.postcode ? '-' + removeCarriageReturns(element.postcode) : '')).substring(0, 15), // "call.id" 
        ((element.jobNo ? removeCarriageReturns(element.jobNo?.toUpperCase()) : '') + (element.postcode ? '-' + removeCarriageReturns(element.postcode) : '')).substring(0, 15), // "cust.id",
        dateNotifiedString,             // "call.text01",
        user ? user.userName : element.userId ? element.userId : '',      // "call.text02",
        deliveryDateString,             // "call.text03",
        removeCarriageReturns(element.action === 'Delivery' ? element.transportDepot : element.returnFacility),         // "call.text04",
        element.action,                 // "call.text05",
        removeCarriageReturns(element.jobNo?.toUpperCase()),                  // "call.text06",
        removeCarriageReturns(element.customerName),           // "cust.name",
        removeCarriageReturns(element.postcode),               // "cust.postcode",
        element.address !== null ? removeCarriageReturns(element.address) : '', // "cust.address"
        deliveryHoursString,            // "call.text07",
        removeCarriageReturns(element.description),            // "call.text08",
        element.palletSpaces,           // "call.user01",
        '',                             // "call.text09",
        '',                             // "call.text10",
        element.totalWeight / 1000,            // "call.text11",
        element.totalWeight / 1000,            // "call.user02",
        element.sdrFormReceived ? 'YES' : 'NO',        // "call.text12",
        removeCarriageReturns(element.sdrFormLink),            // "call.text13",
        element.status,                 // "call.text14",
        element.moffettDetails !== null && element.moffettDetails === 'Moffett is required to unload the goods' ? 'YES' :  '',        // "call.text15",
        element.maxVehicleType,        // "call.text16",
        foundAddress ? foundAddress.customers_address_reference?.toString().trim() : '',        // "call.text17",
        foundAddress ? foundAddress.customer_address_name?.toString().trim() : '',        // "call.text18",
        removeCarriageReturns(element.salesOrderNumber),        // "call.text19",
        removeCarriageReturns(element.specialInstructions),        // "call.text20",
        removeCarriageReturns(element.deliveryContact ? element.deliveryContact + ' ' : ''),        // "call.text21",
        element.id, // "call.text22"    
        element.cm_name, // "call.text23"    
        element.cm_company, // "call.text24" 
        (element.deliveryContactNumber ? phoneNumberPattern.test(element.deliveryContactNumber.replaceAll(' ', '')) ? [element.deliveryContactNumber.replaceAll(' ', '').slice(0, 5), ' ', element.deliveryContactNumber.replaceAll(' ', '').slice(5)].join('') : element.deliveryContactNumber : ''), // "call.text25"    
        element.cm_email, // "call.text26"    
        '', // "call.text27"    
    ];
}

async function fetchDataForElements(list, s_users, customerAddresses) {
    let dataRows = [];

    try {
        // Map each element to a promise that fetches data for it
        const fetchPromises = list.map(async (element) => {
            let foundAddress = element.customerAddressID ? customerAddresses.find(a => a.id === element.customerAddressID) : null;
            if (element.customerAddressID && !foundAddress) {
                try {
                    const response = await getRequest(`${apiURL}/addresses/getCustomerAddress/${element.customerAddressID}`);
                    let dataRow = createRow(element, s_users, response.response);
                    dataRows.push(dataRow);
                } catch (error) {
                    console.error("Error fetching data for element:", element, error);
                }
            } else {

                let dataRow = createRow(element, s_users, foundAddress);
                dataRows.push(dataRow);
            }

        });
        // Wait for all promises to resolve
        await Promise.all(fetchPromises);

        // All data has been fetched and pushed into dataArray, return it
        return dataRows;
    } catch (error) {
        console.error("Error fetching data:", error);
        // Handle errors appropriately
        return []; // Return empty array in case of error
    }
}

const createParagonCSVFile = async (workbook, list, fileName, s_users, customerAddresses) => {
    const tabcolour = { tabColor: { argb: 'FF7915E9' } }
    let wsParagon = workbook.addWorksheet(fileName, { views: [{ showGridLines: false }], properties: tabcolour })
    let headers = [
        "call.id",
        "cust.id",
        "call.text01",
        "call.text02",
        "call.text03",
        "call.text04",
        "call.text05",
        "call.text06",
        "cust.name",
        "cust.postcode",
        "cust.longaddr",
        "call.text07",
        "call.text08",
        "call.user01",
        "call.text09",
        "call.text10",
        "call.text11",
        "call.user02",
        "call.text12",
        "call.text13",
        "call.text14",
        "call.text15",
        "call.text16",
        "call.text17",
        "call.text18",
        "call.text19",
        "call.text20",
        "call.text21",
        "call.text22",
        "call.text23",
        "call.text24",
        "call.text25",
        "call.text26",
        "call.text27",
    ];
    fetchDataForElements(list, s_users, customerAddresses).then(async (array) => {
        // Do something with the populated array
        createTable(wsParagon, 'tbl_paragonData', headers, array, 'A1');

        // write to a new buffer
        const buffer = await workbook.csv.writeBuffer();
        var blob = new Blob([buffer])

        saveAs(blob, fileName);
    })
        .catch(error => {
            console.error("Error in fetching and processing data:", error);
        });


}


const createTable = (worksheet, tableName, columnHeaders, rows, startCell = 'A1') => {
    try {
        let columns = []
        columnHeaders.forEach(header => {
            columns.push({ name: header, totalsRowLabel: 'none:', filterButton: true })
        });

        // console.log(`tableName`, tableName)
        const tblName = tableName;

        // add a table to a sheet
        worksheet.addTable({
            name: tblName,
            ref: startCell,
            headerRow: true,
            totalsRow: false,
            style: {
                // theme: 'TableStyleLight9',
                //showRowStripes: false,
            },
            columns: columns,
            rows: rows
        });
    } catch (error) {
        console.error('Error creating table:', error);
    }
}