
import { Button, FormControl, InputLabel, MenuItem, OutlinedInput, Select, Stack } from '@mui/material';
import TextField from '@mui/material/TextField';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import moment from 'moment';
import React, { useState } from 'react';

const DateRangeSelector = ({ handleDateChange, startDate, endDate, minDate, maxOneMonth = false, variant = 'text' }) => {

    const [dateRange, setDateRange] = useState({
        startDate: startDate ? startDate : moment().set({ hour: 0, minute: 0, second: 0 }),
        endDate: endDate ? endDate : moment().set({ hour: 23, minute: 59, second: 59 })
    });

    const [option, setOption] = useState('Today');
    const options = ['Over Due', 'Today', 'Tomorrow', 'Next 3 days', 'Next 7 days', 'Next 14 days'];

    // useEffect(() => {
    //     if (dateRange.startDate && dateRange.endDate) {
    //         handleDateChange(dateRange)
    //     }
    // }, [dateRange])

    const handleOnClick = () => {
        handleDateChange(dateRange);
    }

    const handleDateRange = (key, value) => {
        switch (key) {
            case "startDate":
                if (new Date(value) < new Date(dateRange.endDate) || new Date(value) === new Date(dateRange.endDate)) {
                    if (maxOneMonth && moment.duration(value.diff(dateRange.endDate)).asDays() < -31) {
                        setDateRange({ ...dateRange, [key]: moment(new Date(value)).set({ hour: 0, minute: 0, second: 0 }), endDate: moment(new Date(value)).add(1, 'M').set({ hour: 23, minute: 59, second: 59 }) });
                    } else {
                        setDateRange({ ...dateRange, [key]: moment(new Date(value)).set({ hour: 0, minute: 0, second: 0 }) });
                    }
                } else { // startDate after endDate
                    setDateRange({ ...dateRange, [key]: moment(new Date(value)).set({ hour: 0, minute: 0, second: 0 }), endDate: moment(new Date(value)).set({ hour: 23, minute: 59, second: 59 }) });
                }
                break;
            case "endDate": setDateRange({ ...dateRange, [key]: moment(new Date(value)).set({ hour: 23, minute: 59, second: 59 }) }); break;
            default: setDateRange({ ...dateRange, [key]: value }); break;
        }
        // handleDateChange(dateRange);
    }


    const handleAutoDate = (event) => {
        const numberOfDays = event.target.value;
        let startDate = moment().set({ hour: 0, minute: 0, second: 0 });
        let endDate = moment().set({ hour: 23, minute: 59, second: 59 });
        // startDate = startDate.add(-1, "seconds")

        switch (numberOfDays) {
            case 'Over Due': startDate = moment().add(-1, "month"); break;
            case 'Today': break;
            case 'Tomorrow':
                startDate = startDate.add(1, "days");
                endDate = endDate.add(1, "days");;
                break;
            case 'Next 3 days': endDate = moment(startDate).add(3, "days");; break
            case 'Next 7 days': endDate = moment(startDate).add(7, "days");; break
            case 'Next 14 days': endDate = moment(startDate).add(14, "days");; break
            default: endDate = moment(startDate).add(numberOfDays, "days");; break
        }
        setOption(numberOfDays);
        setDateRange({ ...dateRange, startDate, endDate });
    }

    // const handleClearDateRange = () => {
    //     setDateRange({ ...dateRange, startDate: null, endDate: null })
    // }






    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <Stack direction='row' spacing={2} >
                <FormControl sx={{ minWidth: 215 }} size="small">
                    <InputLabel id="dateRange">Date Range</InputLabel>
                    <Select value={option} onChange={handleAutoDate}
                        input={<OutlinedInput label="Date range" />}
                        labelId="dateRange"
                    >
                        {options.map(opt => (
                            <MenuItem key={opt}
                                value={opt}>
                                {opt}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <Stack direction='row' spacing={1}>
                    <Stack direction='row' spacing={1}>
                        <DatePicker
                            label="Start Date"
                            format='DD/MM/YYYY'
                            value={moment(dateRange.startDate)}
                            onChange={(newValue) => {
                                handleDateRange("startDate", moment(newValue));
                            }}
                            renderInput={(params) => <TextField {...params} value={moment(dateRange.startDate)} size='small' />}

                            minDate={minDate}
                        />

                        <DatePicker
                            label="End Date"
                            value={moment(dateRange.endDate)}
                            format='DD/MM/YYYY'
                            onChange={(newValue) => {
                                handleDateRange("endDate", moment(newValue));
                            }}
                            minDate={moment(dateRange.startDate)}
                            renderInput={(params) => <TextField {...params} value={moment(dateRange.endDate)} size='small' />}
                        />
                    </Stack>
                    <Button onClick={handleOnClick} variant={variant} >Load Data</Button>
                </Stack>
            </Stack>
        </LocalizationProvider>
    )
}

export default DateRangeSelector