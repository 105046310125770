import { getAuth } from 'firebase/auth'
import { useEffect, useState } from 'react'


export const useAuthFetch = (url, canFetch=true) => {

    const [state, setState] = useState({ loading: true, data: null, success: false, error: false, errorMessage: "", status: null })

    useEffect(() => {
        // console.log('url', url)
        setState({ ...state, loading: true, success: false, error: false, errorMessage: "", status: null })
        
        const fetchData = async () => {
            const jwt = await getAuth().currentUser.getIdToken();
            let headers = new Headers()
            headers.append('Authorization', jwt);
            headers.append('Accept', 'application/json');
            headers.append('Content-Type', 'application/json');
           
            var obj = {
                method: 'GET',
                headers,
            }

            
            fetch(url, obj)
                .then(response => response.json())
                .then(response => {
                    // console.log(`response`, response)
                    if (response.success) {
                        switch (response.status) {
                            case 200: setState({ data: response.response, loading: false, success: true, error: false, errorMessage: "", status: response.status }); break;
                            case 404:
                                setState({ data: null, loading: false, success: true, error: true, errorMessage: response.response, status: response.status });
                                // dispatch(setAppMessage({ open: true, severity: "warning", message: response.response }))
                                break;
                            default:
                                setState({ data: null, loading: false, success: true, error: true, errorMessage: response.response, status: response.status });
                                // dispatch(setAppMessage({ open: true, severity: "error", message: response.response }))
                                break;
                        }
                    } else {
                        switch (response.status) {
                            case 400:
                                setState({ data: null, loading: false, success: true, error: true, errorMessage: response.response, status: response.status });
                                // dispatch(setAppMessage({ open: true, severity: "warning", message: "You have not provided the nessesary information to make this request" }))
                                break;

                            default:
                                setState({ data: null, loading: false, success: false, error: true, errorMessage: response.response, status: response.status })
                                // dispatch(setAppMessage({ open: true, severity: "error", message: response.response }))
                                break;
                        }
                    }
                })
                .catch(err => {
                    console.error(`err`, err)
                    setState({ data: null, loading: false, success: false, error: true, errorMessage: err, status: null })
                    // dispatch(setAppMessage({ open: true, severity: "error", message: "There was an Unknown Error" }))
                })
        }

        if(canFetch===true){
            fetchData();
        }

    }, [url,canFetch, setState])

    return state

}