import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, List, ListItem, ListItemText } from "@mui/material";

const ParagonErrorDialog = ({ open, palletSpacesUnset, handleClose }) => {
   
    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth maxWidth="sm"
            >
                <DialogTitle>
                    Error ParagonCSV Download
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        The Pallet Spaces field for the bellow entries is blank. Please complete it in order to export the CSV file.
                    </DialogContentText>
                    <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                        {palletSpacesUnset && palletSpacesUnset.map((value) => (
                            <ListItem
                                key={value.id}
                                disableGutters
                            >
                                <ListItemText primary={value.action + ' - ' + value.projectName + ' - ' + value.jobNo + ' - ' + value.postcode + ' - ' + value.latestDelivery} />
                            </ListItem>
                        ))}
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} autoFocus>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}


export default ParagonErrorDialog;