import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { doc, getFirestore, getDoc } from "firebase/firestore";
import { setUserDetails, clearUserDetails } from '../store/userSlice'
import { setAppMessage } from '../store/appSlice'
import { getAuth } from 'firebase/auth'




export const useGetFirebaseJWT = () => {

    const [state, setState] = useState(null)


    getAuth().currentUser.getIdToken()
        .then(response => setState(response))
        .catch(err => setState(null))
    // console.log(`state`, state)
    return state
}


export const useGetUserDoc = (user) => {
    // console.log(`user`, user)

    const dispatch = useDispatch()

    const [state, setState] = useState({ success: false, userDoc: null })
    const [docFound, setDocFound] = useState(true)
    const [loading, setLoading] = useState(false)



    useEffect(() => {

        const getUserDoc = async () => {
            setLoading(true)
            const db = getFirestore();
            const docRef = doc(db, "users", user.uid);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                const docData = docSnap.data()
                const jwt = await getAuth().currentUser.getIdToken();
                dispatch(setUserDetails({ ...docData, jwt: jwt, uid: user.uid }))
                dispatch(setAppMessage({ open: true, severity: "info", message: `Welcome ${docData.userName}` }))
                setState({ success: true, userDoc: docSnap.data() })
                setDocFound(true)
                setLoading(false)
            } else {
                dispatch(setAppMessage({ open: true, severity: "error", message: `There was no user found` }))
                dispatch(clearUserDetails())
                setDocFound(false)
                setState({ success: false, userDoc: null })
                setLoading(false)
            }
        }


        if (user && docFound && !loading) {
            getUserDoc()
        } else {
            setState({ success: false, userDoc: null })
        }

    }, [user, setState])


    return state

}



