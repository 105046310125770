import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { drivers, setDrivers, setVehicles, vehicles } from "../store/fleetSlice";
import { fleetURL } from "./apiHandler";
import { useAuthFetch } from "./useAuthFetch";


export const useGetAllFleetData = (canFetch) => {
    const [state, setState] = useState({ loading: true, success: false });
    const dispatch = useDispatch();

    const s_drivers = useSelector(drivers);
    const s_vehicles = useSelector(vehicles);

    const [canFetchDrivers, setCanFetchDrivers] = useState(false)
    const [canFetchVehicles, setCanFetchVehicles] = useState(false)

    useEffect(() => {
        if (canFetch) {
            if (!s_drivers) {
                setCanFetchDrivers(true);
            } else {
                setCanFetchDrivers(false);
            }
            if (!s_vehicles) {
                setCanFetchVehicles(true);
            } else {
                setCanFetchVehicles(false);
            }
           

        } else {
            setCanFetchDrivers(false);
            setCanFetchVehicles(false);
        }
        if (s_drivers && s_vehicles) {
            setState({ ...state, loading: false, success: true });
        }
    }, [canFetch, s_drivers, s_vehicles])


    const driversURL = `${fleetURL}/getDrivers`;
    const driverData = useAuthFetch(driversURL, canFetchDrivers);
    const vehiclesURL = `${fleetURL}/getVehicles`;
    const vehiclesData = useAuthFetch(vehiclesURL, canFetchVehicles);

    useEffect(() => {
        if (driverData.loading && vehiclesData.loading) {
            setState({ ...state, loading: true, success: false });
        }
        if (driverData.data) {
            dispatch(setDrivers(driverData.data));
            
        }
        if (vehiclesData.data) {
            dispatch(setVehicles(vehiclesData.data));
        }
    }, [driverData, vehiclesData])

    return state;
}