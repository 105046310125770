import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  parts: null
};


export const partSlice = createSlice({
  name: 'parts',
  initialState,
  reducers: {
    setParts: (state, action) => {
      state.parts = action.payload;
    }
  }
});

export const { setParts } = partSlice.actions;

export const parts = (state) => state.parts.parts;

export default partSlice.reducer;
