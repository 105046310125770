




import { getStorage, ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { devMode } from "./devMode";

export const getStorageRef = (folderFileName) => {
    let storageRef; const storage = getStorage();
    if (devMode) { storageRef = ref(storage, `tesco/edcDev/${folderFileName}`); }
    else { storageRef = ref(storage, `tesco/edc/${folderFileName}`); }
    return storageRef
}


export const uploadFileHandler = (blob, fileName, type) => {
    return new Promise(async (resolve, reject) => {
        let storageRef = null;
        switch (type) {
            case "Transport Requisition": storageRef = getStorageRef(`transportRequisition/${fileName}`); break;
            default: console.error('Invalid Type', type); break;
        }
        let uploadError = false
        let result = null
        if (!storageRef) {
            reject({
                success: false, response: "Invalid Type"
            })
        }
        else {
            result = await uploadFile(blob, fileName, storageRef).catch(err => {
                console.error('err', err)
                uploadError = true
            })
        } if (uploadError) {
            reject({
                success: false, response: "Error Uploading File"
            })
        }
        else {
            resolve({ success: true, response: result.response })
        }
    })
}

export const uploadFile = (blob, fileName, storageRef) => {
    return new Promise((resolve, reject) => {
        var file = new File([blob], fileName, { lastModified: 1534584790000, type: blob.type }); const uploadTask = uploadBytesResumable(storageRef, file);
        uploadTask.on('state_changed', (snapshot) => {
            // const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;                
            //console.log('Upload is ' + progress + '% done');                
            switch (snapshot.state) {
                case 'paused':
                    //console.log('Upload is paused');                        
                    break;
                case 'running':
                    //console.log('Upload is running');                       
                    break;
                default: break;
            }
        }, (error) => {
            switch (error.code) {
                case 'storage/unauthorized': reject({ success: false, response: "User doesn't have permission to access the object" })
                    break;
                case 'storage/canceled': reject({ success: false, response: "User canceled the upload" })
                    break;
                case 'storage/unknown': console.error(`error`, error)
                    reject({ success: false, response: "Unknown error occurred" })
                    break;
                default: console.error(`error`, error)
                    reject({ success: false, response: "Unknown error occurred" })
                    break;
            }
        }, () => { getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => { resolve({ success: true, response: downloadURL }) }); });
    })
}