import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addresses, customerAddresses, setAddresses, setCustomerAddresses } from "../store/addressSlice";
import { setAppVersion } from "../store/appSlice";
import { parts, setParts } from "../store/partSlice";
import { setUsers, users } from "../store/userSlice";
import { addressURL, edcUser, foURL } from "./apiHandler";
import { useAuthFetch } from "./useAuthFetch";


export const useGetAllTransportRequisitionData = (canFetch) => {
    const [state, setState] = useState({ loading: true, success: false });
    const dispatch = useDispatch();

    // const s_requisitions = useSelector(requisitions);
    const s_users = useSelector(users);
    const s_parts = useSelector(parts);
    const s_customerAddresses = useSelector(customerAddresses);
    // const s_unapprovedRequests = useSelector(unapprovedRequests);

    const [canFetchReqs, setCanFetchReqs] = useState(false)
    const [canFetchUsers, setCanFetchUsers] = useState(false)
    const [canFetchParts, setCanFetchParts] = useState(false)
    const [canFetchCustomerAddresses, setCanFetchCustomerAddresses] = useState(false)

    // const getTransportData = async () => {
    //     let start = new Date();
    //     let end = new Date();

    //     start.setHours(0, 0, 0);
    //     end.setHours(23, 59, 59);
    //     const filterBy = {
    //         "showOnly": [
    //             "Collection",
    //             "Delivery",
    //             "SSL Collection",
    //             "TSL Collection"
    //         ],
    //         "startDate": formatDate(start),
    //         "endDate": formatDate(end)
    //     }
    //     const response = await getTransportRequisitions(filterBy)
    //         .catch((error) => {
    //             console.error('error', error);
    //         });
    //     dispatch(setRequisitions(response.response));
    // }
    useEffect(() => {
        if (canFetch) {
            // if (!s_requisitions) {
            //     // setCanFetchReqs(true);
            //     // getTransportData();
            // } else {
            //     setCanFetchReqs(false);
            // }
            if (!s_users) {
                setCanFetchUsers(true);
            } else {
                setCanFetchUsers(false);
            }
            if (!s_parts) {
                setCanFetchParts(true);
            } else {
                setCanFetchParts(false);
            }
            if (!s_customerAddresses) {
                setCanFetchCustomerAddresses(true);
            } else {
                setCanFetchCustomerAddresses(false);
            }

        } else {
            // setCanFetchReqs(false);
            setCanFetchParts(false);
            setCanFetchUsers(false);
        }
        if (s_users && s_parts && s_customerAddresses) {
            setState({ ...state, loading: false, success: true });
        }
    }, [canFetch, s_users, s_parts, s_customerAddresses])


    // const requisitionsURL = `${transportRequestURL}/getRequests`;
    // const requisitionData = useAuthFetch(requisitionsURL, canFetchReqs);
    const usersURL = `${edcUser}/user`;
    const usersData = useAuthFetch(usersURL, canFetchUsers);
    const partsURL = `${foURL}/parts`;
    const partsData = useAuthFetch(partsURL, canFetchParts);
    const customerAddressesURL = `${addressURL}/getCustomerAddresses`
    const customerAddressesData = useAuthFetch(customerAddressesURL, canFetchCustomerAddresses);

    useEffect(() => {
        if (usersData.status === 505 || partsData.status === 505 || customerAddressesData.status === 505) {
            dispatch(setAppVersion('CONFLICT'));
        } else {
            if (usersData.loading && partsData.loading && customerAddressesData.loading) {
                setState({ ...state, loading: true, success: false });
            }
            // if (requisitionData.data) {
            //     // if(!s_unapprovedRequests) {
            //     //     dispatch(setUnapprovedRequests(requisitionData.data.filter(el => el.isApproved === 0 || el.isApproved === '0')));
            //     // }
            //     dispatch(setRequisitions(requisitionData.data));

            // }
            if (usersData.data) {
                dispatch(setUsers(usersData.data));
            }
            if (partsData.data) {
                dispatch(setParts(partsData.data));
            }
            if (customerAddressesData.data) {
                dispatch(setCustomerAddresses(customerAddressesData.data));
            }
        }
    }, [usersData, partsData, customerAddressesData])

    return state;
}