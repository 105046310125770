import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import moment from 'moment';
import { uploadFileHandler } from '../db/firebase';
// import { removeIllegalAndSpaces, removeIllegalExcel } from '../../../../helpers/textFunctions/textFunctions';



export const createTransportRequest = async (formData, equipmentList, shouldDownload = true) => {
    return new Promise(async (resolve, reject) => {

        const action = formData.action;
        const jobNo = formData.jobNo;
        const projectName = formData.projectName

        //CREATE FILE NAME
        let fileName = `Transport-Requisition-${action}_${projectName}-${jobNo}.xlsx`
        // fileName = removeIllegalAndSpaces(fileName)


        //CREATE EXCEL WORKBOOK
        let workbook = new ExcelJS.Workbook();

        //Set Document Properties
        const dateNow = new Date()
        workbook.creator = 'FitOutUK';
        workbook.lastModifiedBy = 'FitOutUK';
        workbook.created = dateNow;
        workbook.modified = dateNow;
        //CRATE THE TransportRequisition SHEET
        createTransportRequisitionSheet(workbook, formData, equipmentList)


        // write to a new buffer
        const buffer = await workbook.xlsx.writeBuffer();
        var blob = new Blob([buffer])
        let wasErrorCreating = false
        // } catch {
        //     wasErrorCreating = true
        // }


        let wasSaveError = true
        let uploadURL = ""
        if (!wasErrorCreating) {
            if (shouldDownload) {
                try {
                    saveAs(blob, fileName);
                    wasSaveError = false
                }
                catch {
                    wasSaveError = true
                }
            // }
            } else {
                //return ([blob, fileName]);
                await uploadFileHandler(blob, fileName, "Transport Requisition")
                    .then(response => {

                        wasSaveError = false
                        uploadURL = response.response
                    })
                    .catch(err => {
                        console.error(`err`, err)
                        wasSaveError = true
                    })
            }
        }


        if (wasErrorCreating) {
            reject("There was an error creating the excel document")
        } else if (wasSaveError) {
            reject("There was an error uploading the excel document")
        } else if (shouldDownload) {
            resolve("Downloading")
        } else {
            resolve(uploadURL)
            // console.log(`uploadURL`, uploadURL)
        }
    })
}





const createTransportRequisitionSheet = (workbook, formData, equipmentList) => {

    const tabcolour = { tabColor: { argb: 'FF7915E9' } }
    let wsTransportRequisition = workbook.addWorksheet(formData.action, { views: [{ showGridLines: false }], properties: tabcolour })

    //PROJECT TYPE HEADER
    wsTransportRequisition.mergeCells('A2:E2');
    wsTransportRequisition.getRow(2).height = 60;
    let cProjectTypeHeader = wsTransportRequisition.getCell('A2');
    cProjectTypeHeader.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FF538DD5' } }
    cProjectTypeHeader.font = { color: { argb: 'FFFFFFFF' }, size: 20, bold: true }
    cProjectTypeHeader.alignment = { vertical: 'middle', horizontal: 'left' }
    cProjectTypeHeader.value = `TRANSPORT REQUISITION - ${formData.action.toUpperCase()}`


    //PROJECT INFO HEADER
    wsTransportRequisition.mergeCells('A5:E5');
    wsTransportRequisition.getRow(5).height = 30;
    let cProjectInfoHeader = wsTransportRequisition.getCell('A5');
    cProjectInfoHeader.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFA6A6A6' } }
    cProjectInfoHeader.font = { color: { argb: 'FFFFFFFF' }, size: 20 }
    cProjectInfoHeader.alignment = { vertical: 'middle', horizontal: 'left' }
    cProjectInfoHeader.value = "Project Info"


    //Project Name
    let cProjectNameTitle = wsTransportRequisition.getCell('A7');
    cProjectNameTitle.font = { bold: true }
    cProjectNameTitle.border = { bottom: { style: "thin" } };
    cProjectNameTitle.value = "Project Name"
    let cProjectName = wsTransportRequisition.getCell('B7');
    cProjectName.value = formData.projectName
    cProjectName.border = { bottom: { style: "thin" } };

    //Job No
    let cJobNoTitle = wsTransportRequisition.getCell('A8');
    cJobNoTitle.font = { bold: true }
    cJobNoTitle.value = "Job No."
    cJobNoTitle.border = { bottom: { style: "thin" } };
    let cJobNo = wsTransportRequisition.getCell('B8');
    cJobNo.value = formData.jobNo
    cJobNo.border = { bottom: { style: "thin" } };

    //Customer Name
    let cCustomerNameTitle = wsTransportRequisition.getCell('A9');
    cCustomerNameTitle.font = { bold: true }
    cCustomerNameTitle.border = { bottom: { style: "thin" } };
    cCustomerNameTitle.value = "Customer Name"
    let cCustomerName = wsTransportRequisition.getCell('B9');
    cCustomerName.border = { bottom: { style: "thin" } };
    cCustomerName.value = formData.customerName



    // Project Number or CPAR
    if (formData.action === 'TSL Collection' || formData.action === 'SSL Collection') {
        let cProjectNumberCPARTitle = wsTransportRequisition.getCell('A10');
        cProjectNumberCPARTitle.font = { bold: true }
        cProjectNumberCPARTitle.border = { bottom: { style: "thin" } };
        if (formData.action === 'TSL Collection') {
            cProjectNumberCPARTitle.value = "Project Number"
        } else {
            cProjectNumberCPARTitle.value = "CPAR"
        }
        let cProjectNumberCPAR = wsTransportRequisition.getCell('B10');
        cProjectNumberCPAR.border = { bottom: { style: "thin" } };
        cProjectNumberCPAR.value = formData.projectNumberCpar
    }

    //ADDRESS

    let cAddressTitle = wsTransportRequisition.getCell('A11');
    cAddressTitle.font = { bold: true }
    cAddressTitle.border = { bottom: { style: "thin" } };
    cAddressTitle.value = "Address"
    let cAddress = wsTransportRequisition.getCell('B11');
    cAddress.border = { bottom: { style: "thin" } };
    cAddress.value = formData.address

    //Postcode
    let cPoscodeTitle = wsTransportRequisition.getCell('A12');
    cPoscodeTitle.font = { bold: true }
    cPoscodeTitle.border = { bottom: { style: "thin" } };
    cPoscodeTitle.value = "Postcode"
    let cPoscode = wsTransportRequisition.getCell('B12');
    cPoscode.value = formData.postcode
    cPoscode.border = { bottom: { style: "thin" } };


    //DELIVERY INFO HEADER
    wsTransportRequisition.mergeCells('A14:E14');
    wsTransportRequisition.getRow(5).height = 30;
    let cDeliveryInfoHeader = wsTransportRequisition.getCell('A14');
    cDeliveryInfoHeader.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFA6A6A6' } }
    cDeliveryInfoHeader.font = { color: { argb: 'FFFFFFFF' }, size: 20 }
    cDeliveryInfoHeader.alignment = { vertical: 'middle', horizontal: 'left' }
    cDeliveryInfoHeader.value = "Delivery Info"



    //Earliest Delivery
    let cEarliestDeliveryTitle = wsTransportRequisition.getCell('A16');
    cEarliestDeliveryTitle.font = { bold: true }
    cEarliestDeliveryTitle.border = { bottom: { style: "thin" } };
    cEarliestDeliveryTitle.value = "Earliest Delivery"
    let cEarliestDelivery = wsTransportRequisition.getCell('B16');
    cEarliestDelivery.border = { bottom: { style: "thin" } };
    cEarliestDelivery.value = moment(formData.earliestDelivery).format("ddd DD/MM/YYYY @ HH:mm").toString()

    //Latest Delivery
    let cLatestDeliveryTitle = wsTransportRequisition.getCell('A17');
    cLatestDeliveryTitle.font = { bold: true }
    cLatestDeliveryTitle.border = { bottom: { style: "thin" } };
    cLatestDeliveryTitle.value = "Latest Delivery"
    let cLatestDelivery = wsTransportRequisition.getCell('B17');
    cLatestDelivery.border = { bottom: { style: "thin" } };
    cLatestDelivery.value = moment(formData.latestDelivery).format("ddd DD/MM/YYYY @ HH:mm").toString()

    //SDR Form Link
    let cSDRFormLinkTitle = wsTransportRequisition.getCell('A18');
    cSDRFormLinkTitle.border = { bottom: { style: "thin" } };
    cSDRFormLinkTitle.font = { bold: true }
    cSDRFormLinkTitle.value = "SDR Form Link"
    let cSDRFormLink = wsTransportRequisition.getCell('B18');
    cSDRFormLink.border = { bottom: { style: "thin" } };
    cSDRFormLink.value = formData.sdrFormLink;

    //SDR Form Received
    let cSDRformReceivedTitle = wsTransportRequisition.getCell('A19');
    cSDRformReceivedTitle.border = { bottom: { style: "thin" } };
    cSDRformReceivedTitle.font = { bold: true }
    cSDRformReceivedTitle.value = "SDR Form Received"
    let cSDRformReceived = wsTransportRequisition.getCell('B19');
    cSDRformReceived.border = { bottom: { style: "thin" } };
    cSDRformReceived.value = formData.sdrFormReceived  ? "Yes" : "No";

    if (formData.action === 'Delivery') {
        //Transport Depot
        let cTransportDepotTitle = wsTransportRequisition.getCell('A20');
        cTransportDepotTitle.border = { bottom: { style: "thin" } };
        cTransportDepotTitle.font = { bold: true }
        cTransportDepotTitle.value = "Transport Depot"
        let cTransportDepot = wsTransportRequisition.getCell('B20');
        cTransportDepot.border = { bottom: { style: "thin" } };
        cTransportDepot.value = formData.transportDepot;

    }

    //PALLETS INFO HEADER
    wsTransportRequisition.mergeCells('A23:E22');
    wsTransportRequisition.getRow(5).height = 30;
    let cPalletsInfoHeader = wsTransportRequisition.getCell('A22');
    cPalletsInfoHeader.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFA6A6A6' } }
    cPalletsInfoHeader.font = { color: { argb: 'FFFFFFFF' }, size: 20 }
    cPalletsInfoHeader.alignment = { vertical: 'middle', horizontal: 'left' }
    cPalletsInfoHeader.value = "Pallets Info"


    //Pallet Spaces
    let cPalletSpacesTitle = wsTransportRequisition.getCell('A24');
    cPalletSpacesTitle.font = { bold: true }
    cPalletSpacesTitle.border = { bottom: { style: "thin" } };
    cPalletSpacesTitle.value = "Pallet Spaces"
    let cPalletSpaces = wsTransportRequisition.getCell('B24');
    cPalletSpaces.border = { bottom: { style: "thin" } };
    cPalletSpaces.value = formData.palletSpaces


    //Qty Standard Pallets
    let cStandardPalletsTitle = wsTransportRequisition.getCell('A25');
    cStandardPalletsTitle.font = { bold: true }
    cStandardPalletsTitle.border = { bottom: { style: "thin" } };
    cStandardPalletsTitle.value = "Qty Standard Pallets"
    let cStandardPallets = wsTransportRequisition.getCell('B25');
    cStandardPallets.border = { bottom: { style: "thin" } };
    cStandardPallets.value = formData.qtyPalletStandard

    //Qty Oversized Pallets
    let cOversizedPalletsTitle = wsTransportRequisition.getCell('A26');
    cOversizedPalletsTitle.font = { bold: true }
    cOversizedPalletsTitle.border = { bottom: { style: "thin" } };
    cOversizedPalletsTitle.value = "Contractor Name"
    let cOversizedPallets = wsTransportRequisition.getCell('B26');
    cOversizedPallets.border = { bottom: { style: "thin" } };
    cOversizedPallets.value = formData.qtyPalletOversized

    //Total Weight
    let cTotalWeightTitle = wsTransportRequisition.getCell('A27');
    cTotalWeightTitle.font = { bold: true }
    cTotalWeightTitle.border = { bottom: { style: "thin" } };
    cTotalWeightTitle.value = "Total Weight"
    let cTotalWeight = wsTransportRequisition.getCell('B27');
    cTotalWeight.border = { bottom: { style: "thin" } };
    cTotalWeight.value = formData.totalWeight

    //Max Vehicle Type Permited
    let cMaxVehicleTitle = wsTransportRequisition.getCell('A28');
    cMaxVehicleTitle.font = { bold: true }
    cMaxVehicleTitle.border = { bottom: { style: "thin" } };
    cMaxVehicleTitle.value = "Max Vehicle Type"
    let cMaxVehicle = wsTransportRequisition.getCell('B28');
    cMaxVehicle.border = { bottom: { style: "thin" } };
    cMaxVehicle.value = formData.maxVehicleType


    //CONTACT INFO HEADER
    wsTransportRequisition.mergeCells('A30:E30');
    wsTransportRequisition.getRow(5).height = 30;
    let cContactInfoHeader = wsTransportRequisition.getCell('A30');
    cContactInfoHeader.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFA6A6A6' } }
    cContactInfoHeader.font = { color: { argb: 'FFFFFFFF' }, size: 20 }
    cContactInfoHeader.alignment = { vertical: 'middle', horizontal: 'left' }
    cContactInfoHeader.value = "Contact Info"

    //Contact Name
    let cContactNameTitle = wsTransportRequisition.getCell('A31');
    cContactNameTitle.font = { bold: true }
    cContactNameTitle.border = { bottom: { style: "thin" } };
    cContactNameTitle.value = "Contact Name"
    let cContactName = wsTransportRequisition.getCell('B31');
    cContactName.border = { bottom: { style: "thin" } };
    cContactName.value = formData.deliveryContact

    //Contact Number
    let cContactNumberTitle = wsTransportRequisition.getCell('A32');
    cContactNumberTitle.font = { bold: true }
    cContactNumberTitle.border = { bottom: { style: "thin" } };
    cContactNumberTitle.value = "Contact Number"
    let cContactNumber = wsTransportRequisition.getCell('B32');
    cContactNumber.border = { bottom: { style: "thin" } };
    cContactNumber.value = formData.deliveryContactNumber

    if (formData.action !== 'Delivery') {
        //Location of Equipment
        let cLocationOfEquipmentTitle = wsTransportRequisition.getCell('A33');
        cLocationOfEquipmentTitle.font = { bold: true }
        cLocationOfEquipmentTitle.border = { bottom: { style: "thin" } };
        cLocationOfEquipmentTitle.value = "Location of Equipment"
        let cLocationOfEquipment = wsTransportRequisition.getCell('B33');
        cLocationOfEquipment.border = { bottom: { style: "thin" } };
        cLocationOfEquipment.value = formData.locationOfEquipment
    }

    if (formData.action === 'TSL Collection' || formData.action === 'SSL Collection') {
        //Project Main Contractor
        let cMainContractorTitle = wsTransportRequisition.getCell('A34');
        cMainContractorTitle.font = { bold: true }
        cMainContractorTitle.border = { bottom: { style: "thin" } };
        cMainContractorTitle.value = "Project Main Contractor"
        let cMainContractor = wsTransportRequisition.getCell('B34');
        cMainContractor.border = { bottom: { style: "thin" } };
        cMainContractor.value = formData.projectMainContractor;
    }

    //FORK LIFT TRUCK AVAILABLE
    let cForkLiftTitle = wsTransportRequisition.getCell('D31');
    cForkLiftTitle.font = { bold: true }
    cForkLiftTitle.border = { bottom: { style: "thin" } };
    cForkLiftTitle.value = "Fork Lift Truck Available?"
    let cForklift = wsTransportRequisition.getCell('E31');
    cForklift.value = formData.forkliftAvailable  ? "Yes" : "No"
    cForklift.border = { bottom: { style: "thin" } };

    //MOFFETT REQUIRED
    let cMoffettTitle = wsTransportRequisition.getCell('D32');
    cMoffettTitle.font = { bold: true }
    cMoffettTitle.border = { bottom: { style: "thin" } };
    cMoffettTitle.value = "Moffett Required?"
    let cMoffett = wsTransportRequisition.getCell('E32');
    cMoffett.border = { bottom: { style: "thin" } };
    cMoffett.value = formData.moffettDetails && formData.moffettDetails === 'Moffett is required to unload the goods' ? "Yes" : "No"

    if (formData.action !== 'Delivery') {
        //LABOUR ON SITE
        let cLabourTitle = wsTransportRequisition.getCell('D34');
        cLabourTitle.font = { bold: true }
        cLabourTitle.border = { bottom: { style: "thin" } };
        cLabourTitle.value = "Labour on Site?"
        let cLabour = wsTransportRequisition.getCell('E34');
        cLabour.border = { bottom: { style: "thin" } };
        cLabour.value = formData.labourOnSite  ? "Yes" : "No"
        
        //DEDICATED VEHICLE
        let cDedicatedVehicleTitle = wsTransportRequisition.getCell('D33');
        cDedicatedVehicleTitle.font = { bold: true }
        cDedicatedVehicleTitle.border = { bottom: { style: "thin" } };
        cDedicatedVehicleTitle.value = "Dedicated Vehicle Required?"
        let cDedicatedVehicle = wsTransportRequisition.getCell('E33');
        cDedicatedVehicle.border = { bottom: { style: "thin" } };
        cDedicatedVehicle.value = formData.dedicatedVehicleRequired  ? "Yes" : "No"
    }

    //DESCRIPTION
    let cDescriptionTitle = wsTransportRequisition.getCell('A35');
    cDescriptionTitle.font = { bold: true }
    cDescriptionTitle.border = { bottom: { style: "thin" } };
    cDescriptionTitle.value = "Description"
    let cDescription = wsTransportRequisition.getCell('B35');
    cDescription.value = formData.description
    cDescription.border = { bottom: { style: "thin" } };

    //SPECIAL INSTRUCTIONS
    let cSpecialInstructionsTitle = wsTransportRequisition.getCell('A36');
    cSpecialInstructionsTitle.font = { bold: true }
    cSpecialInstructionsTitle.border = { bottom: { style: "thin" } };
    cSpecialInstructionsTitle.value = "Special Instructions"
    let cSpecialInstructions = wsTransportRequisition.getCell('B36');
    cSpecialInstructions.value = formData.specialInstructions
    cSpecialInstructions.border = { bottom: { style: "thin" } };


    //EQUIPMENT INFO
    if (formData.action === 'Delivery' || formData.action === 'Collection') {
        wsTransportRequisition.mergeCells('A38:D38');
    } else {
        wsTransportRequisition.mergeCells('A38:E38');
    }
    let phaseSectionTitle1 = wsTransportRequisition.getCell('A38');
    phaseSectionTitle1.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFA6A6A6' } }
    phaseSectionTitle1.font = { color: { argb: 'FFFFFFFF' }, size: 20 }
    phaseSectionTitle1.alignment = { vertical: 'middle', horizontal: 'left' }
    phaseSectionTitle1.value = "EQUIPMENT INFORMATION"

    let headers = [];
    let dataRows = [];
    switch (formData.action) {
        case 'Delivery':
            headers = ["Line", "Description", "Qty", "Dept"];
            equipmentList.forEach((element, index) => {
                const description = element.equipment;
                const dept = element.dept;
                const qty = element.quantity;
                let dataRow = [index + 1, description, qty, dept]
                dataRows.push(dataRow)
            });
            break;
        case 'Collection':
            headers = ["Line", "Description", "Qty", "Action"];
            equipmentList.forEach((element, index) => {
                const description = element.equipment;
                const action = element.action;
                const qty = element.quantity
                let dataRow = [index + 1, description, qty, action]
                dataRows.push(dataRow)
            });
            break;
        default:
            headers = ["Line", "Description", "Qty", "Palets Or Cages", "New Or Used"];
            equipmentList.forEach((element, index) => {
                const description = element.equipment;
                const palletsOrCages = element.palletsOrCages
                const newOrUsed = element.newOrUsed
                const qty = element.quantity;
                let dataRow = [index + 1, description, qty, palletsOrCages, newOrUsed]
                dataRows.push(dataRow)
            });
            break;
    }

    createTable(wsTransportRequisition, 'tbl_equipment', headers, dataRows, 'A39')

    const deliveryWidths = [23, 35, 18, 28, 25]
    deliveryWidths.forEach((width, index) => { wsTransportRequisition.getColumn(index + 1).width = width; });
}


const createTable = (worksheet, tableName, columnHeaders, rows, startCell = 'A1') => {

    let columns = []
    columnHeaders.forEach(header => {
        columns.push({ name: header, totalsRowLabel: 'none:', filterButton: true })
    });

    // console.log(`tableName`, tableName)
    const tblName = tableName;

    // add a table to a sheet
    worksheet.addTable({
        name: tblName,
        ref: startCell,
        headerRow: true,
        totalsRow: false,
        style: {
            // theme: 'TableStyleLight9',
            //showRowStripes: false,
        },
        columns: columns,
        rows: rows
    });
}