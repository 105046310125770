import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { NoWrapType } from "../../../utils/NoWrapType";



const ChooseJobsParagonDialog = ({ open, setOpen, exportParagonFile, requisitionList, paragonList, selectedJobs, setSelectedJobs, handleClose }) => {

    const onSelectAllClick = () => {
        if (selectedJobs.length < paragonList.length) {
            setSelectedJobs(paragonList);
        } else {
            setSelectedJobs([]);
        }
    }

    const handleCloseChooseJobsDialogConfirm = () => {
        let pList = requisitionList.filter(el => el.exportToParagon === null || el.exportToParagon === 0 || el.exportToParagon === '0');
        if (selectedJobs && selectedJobs.length > 0) {
            selectedJobs.forEach(el => pList.push(el))
        }
        exportParagonFile(pList);
        setOpen(false);
    }

    
   

    return (
        <div>
            < Dialog
                open={open}
                onClose={handleClose}
                fullWidth maxWidth="l"
            >
                <DialogTitle>
                    Download ParagonCSV File
                </DialogTitle>
                <DialogContent>
                    <Typography sx={{ whiteSpace: 'pre-line', marginBottom: '1vh' }}>
                        Please note that the following transport requisitions have been exported.
                        Please select the ones you want to include in the CSV file.
                    </Typography>
                    <TableContainer component={Paper} /*sx={{ maxHeight: '63vh' }}*/>
                        <Table size="small" aria-label="a dense table"  >
                            <TableHead sx={{
                                backgroundColor: 'primary.main',
                                color: 'primary.contrastText',
                                position: 'sticky',
                                top: 0
                            }}>

                                <TableRow>
                                    <TableCell sx={{ color: "inherit", width: 10 }} align="left" key='checkbox'>
                                        <Checkbox
                                            style={{ color: paragonList && paragonList.length === selectedJobs.length ? "white" : "primary" }}
                                            // indeterminate={numSelected > 0 && numSelected < rowCount}
                                            checked={paragonList && paragonList.length === selectedJobs.length}
                                            onChange={onSelectAllClick}
                                        />
                                    </TableCell>
                                    <TableCell sx={{ color: "inherit", width: 10 }} align="left" key='boxHeader'></TableCell>
                                    <TableCell sx={{ color: "inherit", width: 150 }} align="left">Project name</TableCell>
                                    <TableCell sx={{ color: "inherit", width: 100 }} align="left">Consignee Company</TableCell>
                                    <TableCell sx={{ color: "inherit", width: 250 }} align="left">Action</TableCell>
                                    <TableCell sx={{ color: "inherit", width: 150 }} align="left">Latest Delivery</TableCell>
                                    <TableCell sx={{ color: "inherit", width: 150 }} align="left">Job No.</TableCell>
                                    <TableCell sx={{ color: "inherit", width: 200 }} align="left">Postcode</TableCell>
                                    <TableCell sx={{ color: "inherit", width: 200 }} align="left">Loading Depot</TableCell>
                                    <TableCell sx={{ color: "inherit", width: 200 }} align="left">Pallet Spaces</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {paragonList && paragonList.map((row, index) => (
                                    <TableRow
                                        hover={selectedJobs.indexOf(row) > -1 ? false : true}
                                        key={index}
                                        sx={{
                                            '&:last-child td, &:last-child th': { border: 0 },
                                            backgroundColor: selectedJobs.indexOf(row) > -1 && 'aliceblue',
                                            color: selectedJobs.indexOf(row) > -1 && 'black',
                                        }}
                                    >
                                        <TableCell>
                                            <Checkbox
                                                color="primary"
                                                // indeterminate={numSelected > 0 && numSelected < rowCount}
                                                checked={selectedJobs.indexOf(row) > -1}
                                                onChange={() => {
                                                    let a = [];
                                                    if (selectedJobs.indexOf(row) > -1) {
                                                        a = selectedJobs.filter(el => el !== row);
                                                        setSelectedJobs(a);
                                                    } else {
                                                        setSelectedJobs(selectedJobs.concat(row));
                                                    }
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell key='box'>
                                            <Box allign="left"
                                                sx={{
                                                    width: 7,
                                                    height: 25,
                                                    backgroundColor: row.action === 'Delivery' ? ((row.projectName && row.projectName.toLowerCase().indexOf('tesco') !== -1) ||
                                                        (row.customerName && row.customerName.toLowerCase().indexOf('tesco') !== -1) ? 'primary.dark' :
                                                        ((row.projectName && (row.projectName.toLowerCase().indexOf('sainsbury') !== -1 ||
                                                            row.projectName.toLowerCase().indexOf('ssl') !== -1)) ||
                                                            (row.customerName && (row.customerName.toLowerCase().indexOf('sainsbury') !== -1 ||
                                                                row.customerName.toLowerCase().indexOf('ssl') !== -1)) ? 'orange' : '#119dda')) : 'green'
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell sx={{ color: 'inherit', maxWidth: 120 }} align="left"><NoWrapType text={row.projectName} /></TableCell>
                                        <TableCell sx={{ color: 'inherit', maxWidth: 120 }} align="left"><NoWrapType text={row.customerName} /></TableCell>
                                        <TableCell sx={{ color: 'inherit', maxWidth: 200 }} align="left"><NoWrapType text={row.action} /></TableCell>
                                        <TableCell sx={{ color: 'inherit', maxWidth: 120 }} align="left"><NoWrapType text={row.latestDelivery} /></TableCell>
                                        <TableCell sx={{ color: 'inherit', maxWidth: 120 }} align="left"><NoWrapType text={row.jobNo} /></TableCell>
                                        <TableCell sx={{ color: 'inherit', maxWidth: 120 }} align="left"><NoWrapType text={row.postcode} /></TableCell>
                                        <TableCell sx={{ color: 'inherit', maxWidth: 120 }} align="left"><NoWrapType text={row.transportDepot} /></TableCell>
                                        <TableCell sx={{ color: 'inherit', maxWidth: 120 }} align="left"><NoWrapType text={row.palletSpaces} /></TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>

                    </TableContainer>
                </DialogContent>
                <DialogActions sx={{ alignSelf: 'center' }}>
                    <Button onClick={handleClose} autoFocus variant='outlined'>
                        CANCEL
                    </Button>
                    <Button onClick={handleCloseChooseJobsDialogConfirm} autoFocus variant='contained'>
                        DOWNLOAD
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}


export default ChooseJobsParagonDialog;