import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";

const DeleteRequestDialog = ({ open, setOpen, selectedRows, deleteReason, setDeleteReason, canDelete, handleDeleteTransportRequisition, handleClose }) => {

    const handleReasonChange = (e) => {
        const value = e.target.value;
        setDeleteReason(value);
    }

    const handleCloseConfirm = () => {
        setOpen(false);
        handleDeleteTransportRequisition();
    }

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth maxWidth="sm"
            >
                <DialogTitle>
                    {selectedRows.length > 1 ? 'Delete Transport Requisitions' : 'Delete Transport Requisition'}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {selectedRows.length > 1 ? 'Are you sure you want to delete all this entries?' : 'Are you sure you want to delete this entry?'}
                    </DialogContentText>
                    <TextField required autoComplete='off' variant='standard' size='small' name='reason' label='Reason for deleting'
                        sx={{ marginTop: 1 }} value={deleteReason} onChange={handleReasonChange} fullWidth />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleCloseConfirm} autoFocus disabled={!canDelete}>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default DeleteRequestDialog;