import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { TabContext, TabPanel } from "@mui/lab";
import { Alert, Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, Snackbar, Stack, Tab, Tabs, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteRequest, fleetURL } from '../../db/apiHandler';
import { deleteSelectedDriver, deleteSelectedVehicle, drivers, vehicles } from '../../store/fleetSlice';
import DriverFormDialog from './DriverFormDialog';
import DriversTable from "./DriversTable";
import FleetTable from './FleetTable';
import VehicleFormDialog from './VehicleFormDialog';
import VehiclesTable from "./VehiclesTable";

const FleetPage = () => {
    const dispatch = useDispatch();
    const [canLoadDoc, setCanLoadDoc] = useState(false);
    const [success, setSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [tabIndex, setTabIndex] = useState('1');

    const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
    const [openFormDialog, setOpenFormDialog] = useState(false);

    const [vehicleList, setVehicleList] = useState(null);
    const [driversList, setDriversList] = useState(null);
    const [searchField, setSearchField] = useState('');

    const [selectedRow, setSelectedRow] = useState(null);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const [filterBy, setFilterBy] = useState(['Depot', 'Vehicle Group', 'Make', 'Type', 'Registration', 'Drivers', 'Serial ID', 'Moffett Trailer', 'Moffett']);
    const [filterOptions, setFilterOptions] = useState(['Vehicle Group', 'Depot', 'Make', 'Type', 'Registration', 'Drivers', 'Serial ID', 'Moffett Trailer', 'Moffett']);

    const s_vehicles = useSelector(vehicles);
    const s_drivers = useSelector(drivers);

    useEffect(() => {
        let copyArray = [];
        switch (tabIndex) {
            case '1':
                if (searchField !== '') {
                    copyArray = s_vehicles.filter(el =>
                        (filterBy.indexOf('Vehicle Group') > -1 ? el.vehicleGroup ? el.vehicleGroup.toLowerCase().indexOf(searchField.toLowerCase()) !== -1 : false : false) ||
                        (filterBy.indexOf('Depot') > -1 ? el.depot ? el.depot.toLowerCase().indexOf(searchField.toLowerCase()) !== -1 : false : false) ||
                        (filterBy.indexOf('Make') > -1 ? el.make ? el.make.toLowerCase().indexOf(searchField.toLowerCase()) !== -1 : false : false) ||
                        (filterBy.indexOf('Type') > -1 ? el.type ? el.type.toLowerCase().indexOf(searchField.toLowerCase()) !== -1 : false : false) ||
                        (filterBy.indexOf('Registration') > -1 ? el.registration ? el.registration.toLowerCase().indexOf(searchField.toLowerCase()) !== -1 : false : false) ||
                        (filterBy.indexOf('Serial ID') > -1 ? el.serialId ? el.serialId.toLowerCase().indexOf(searchField.toLowerCase()) !== -1 : false : false) ||
                        (filterBy.indexOf('Moffett Trailer') > -1 ? el.moffettTrailer ? el.moffettTrailer.toLowerCase().indexOf(searchField.toLowerCase()) !== -1 : false : false) ||
                        (filterBy.indexOf('Moffett') > -1 ? el.moffett ? el.moffett.toLowerCase().indexOf(searchField.toLowerCase()) !== -1 : false : false) ||
                        (filterBy.indexOf('Drivers') > -1 ? (s_drivers && s_drivers.length > 0 ? (s_drivers.filter(elem => elem.name.toLowerCase().indexOf(searchField.toLowerCase()) !== -1 && elem.primaryVehicle === el.registration).length > 0 && el.registration !== '' ? true : false) : false) : false));

                    setVehicleList(copyArray);
                } else {
                    setVehicleList(s_vehicles);
                }
                break;
            case '2':
                if (searchField !== '') {
                    copyArray = s_vehicles.filter(el =>
                        (filterBy.indexOf('Vehicle Group') > -1 ? el.vehicleGroup ? el.vehicleGroup.toLowerCase().indexOf(searchField.toLowerCase()) !== -1 : false : false) ||
                        (filterBy.indexOf('Depot') > -1 ? el.depot ? el.depot.toLowerCase().indexOf(searchField.toLowerCase()) !== -1 : false : false) ||
                        (filterBy.indexOf('Make') > -1 ? el.make ? el.make.toLowerCase().indexOf(searchField.toLowerCase()) !== -1 : false : false) ||
                        (filterBy.indexOf('Type') > -1 ? el.type ? el.type.toLowerCase().indexOf(searchField.toLowerCase()) !== -1 : false : false) ||
                        (filterBy.indexOf('Registration') > -1 ? el.registration ? el.registration.toLowerCase().indexOf(searchField.toLowerCase()) !== -1 : false : false) ||
                        (filterBy.indexOf('Serial ID') > -1 ? el.serialId ? el.serialId.toLowerCase().indexOf(searchField.toLowerCase()) !== -1 : false : false) ||
                        (filterBy.indexOf('Moffett Trailer') > -1 ? el.moffettTrailer ? el.moffettTrailer.toLowerCase().indexOf(searchField.toLowerCase()) !== -1 : false : false) ||
                        (filterBy.indexOf('Moffett') > -1 ? el.moffett ? el.moffett.toLowerCase().indexOf(searchField.toLowerCase()) !== -1 : false : false) ||
                        (filterBy.indexOf('Phone Number') > -1 ? el.phoneNumber ? el.phoneNumber.toLowerCase().indexOf(searchField.toLowerCase()) !== -1 : false : false));

                    setVehicleList(copyArray);
                } else {
                    setVehicleList(s_vehicles);
                }
                break;
            default:
                if (searchField !== '') {
                    copyArray = s_drivers.filter(el =>
                        (filterBy.indexOf('Name') > -1 ? el.name ? el.name.toLowerCase().indexOf(searchField.toLowerCase()) !== -1 : false : false) ||
                        (filterBy.indexOf('Depot') > -1 ? el.depot ? el.depot.toLowerCase().indexOf(searchField.toLowerCase()) !== -1 : false : false) ||
                        (filterBy.indexOf('Licence Type') > -1 ? el.licenceType ? el.licenceType.toLowerCase().indexOf(searchField.toLowerCase()) !== -1 : false : false) ||
                        (filterBy.indexOf('Primary Vehicle') > -1 ? el.primaryVehicle ? el.primaryVehicle.toLowerCase().indexOf(searchField.toLowerCase()) !== -1 : false : false) ||
                        (filterBy.indexOf('Registration') > -1 ? el.registration ? el.registration.toLowerCase().indexOf(searchField.toLowerCase()) !== -1 : false : false) ||
                        (filterBy.indexOf('Shift') > -1 ? el.shift ? el.shift.toLowerCase().indexOf(searchField.toLowerCase()) !== -1 : false : false));

                    setDriversList(copyArray)
                } else {
                    setDriversList(s_drivers);
                }
                break;
        }

    }, [s_vehicles, s_drivers, filterBy, searchField])


    useEffect(() => {
        setSearchField('');
        switch (tabIndex) {
            case '1':
                setFilterBy(['Depot', 'Vehicle Group', 'Make', 'Type', 'Registration', 'Drivers', 'Serial ID', 'Moffett Trailer', 'Moffett']);
                setFilterOptions(['Vehicle Group', 'Depot', 'Make', 'Type', 'Registration', 'Drivers', 'Serial ID', 'Moffett Trailer', 'Moffett']);
                break;
            case '2':
                setFilterBy(['Vehicle Group', 'Depot', 'Make', 'Type', 'Registration', 'Serial ID', 'Moffett Trailer', 'Moffett', 'Phone Number']);
                setFilterOptions(['Vehicle Group', 'Depot', 'Make', 'Type', 'Registration', 'Serial ID', 'Moffett Trailer', 'Moffett', 'Phone Number']);
                break;

            default: // '3'
                setFilterBy(['Name', 'Licence Type', 'Depot', 'Primary Vehicle', 'Shift']);
                setFilterOptions(['Name', 'Licence Type', 'Depot', 'Primary Vehicle', 'Shift']);
                break;
        }
    }, [tabIndex])


    useEffect(() => {
        if (selectedRow) { setCanLoadDoc(true) } else {
            setCanLoadDoc(false);
        }

    }, [selectedRow])

    const handleSearch = (e) => {
        setPage(0);
        setSearchField(e.target.value);
    }

    const handleChangeFilterSelect = (event) => {
        const {
            target: { value },
        } = event;
        setFilterBy([]);
        setFilterBy(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };
    const handleClickOpen = () => {
        setOpenConfirmationDialog(true);
    };

    const handleClose = () => {
        setOpenConfirmationDialog(false);
    };

    const handleCloseConfirm = async () => {
        setOpenConfirmationDialog(false);
        if (tabIndex === '2') {
            const response = await deleteRequest(`${fleetURL}/deleteVehicle/${selectedRow.id}`)
                .catch((error) => {
                    console.error('error', error)
                });
            if (response.success) {
                setSuccess(true);
                setSuccessMessage("Vehicle successfully deleted.");
                dispatch(deleteSelectedVehicle(selectedRow.id));
            }
        } else {
            const response = await deleteRequest(`${fleetURL}/deleteDriver/${selectedRow.id}`)
                .catch((error) => {
                    console.error('error', error)
                });
            if (response.success) {
                setSuccess(true);
                setSuccessMessage("Driver successfully deleted.");
                dispatch(deleteSelectedDriver(selectedRow.id));
            }
        }
        setSearchField('');
        setCanLoadDoc(false);
    }

    const handleOpenFormCreate = () => {
        setSuccess(false);
        setSelectedRow(null);
        setOpenFormDialog(true);
    };

    const handleOpenFormEdit = () => {
        setSuccess(false);
        setOpenFormDialog(true);
    };

    const handleCloseSnackbar = () => {
        setSuccess(false);
    };

    const handleChangeTab = (event, newValue) => {
        setPage(0);
        setSelectedRow(null);
        setSearchField('')
        setTabIndex(newValue);
    };
    return (
        <Box>
            <Stack spacing={1} sx={{ display: "flex", flexDirection: "column", alignItems: "left" }} >

                <Stack spacing={1} direction="row" >
                    <FilterAltIcon sx={{ fontSize: 32 }}></FilterAltIcon>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="searchFileld"
                        name="searchField"
                        label={tabIndex === '1' ? 'Search...' : tabIndex === '2' ? 'Search vehicle...' : 'Search driver...'}
                        type="text"
                        size="small"
                        value={searchField}
                        onChange={handleSearch}
                        sx={{ width: 250 }}
                    />
                    <FormControl sx={{ width: 250 }} size="small">
                        <InputLabel id="label2">Filter by</InputLabel>
                        <Select
                            labelId="label2"
                            multiple
                            value={filterBy}
                            onChange={handleChangeFilterSelect}
                            input={<OutlinedInput label="Filter by" />}
                            renderValue={(selected) => selected.join(', ')}
                        >
                            {filterOptions.map((option) => (
                                <MenuItem key={option} value={option}>
                                    <Checkbox checked={filterBy.indexOf(option) > -1} />
                                    <ListItemText primary={option} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    {tabIndex !== '1' && <Button variant='contained' color='primary' onClick={handleOpenFormCreate} size="small" startIcon={<AddCircleIcon />}>Create</Button>}

                    {canLoadDoc && <Button variant='contained' color='primary' onClick={handleOpenFormEdit} startIcon={<EditIcon />}>EDIT</Button>}
                    {canLoadDoc && tabIndex !== '1' && <Button variant='contained' color='error' onClick={handleClickOpen} startIcon={<DeleteIcon />}>Delete</Button>}

                </Stack>

            </Stack>
            {(tabIndex === '2' || tabIndex === '1') &&
                <VehicleFormDialog open={openFormDialog} setOpen={setOpenFormDialog} entry={selectedRow} setEntry={setSelectedRow} setSuccess={setSuccess} setSuccessMessage={setSuccessMessage} drivers={s_drivers} tabIndex={tabIndex} />}

            {tabIndex === '3' &&
                <DriverFormDialog open={openFormDialog} setOpen={setOpenFormDialog} entry={selectedRow} setEntry={setSelectedRow} setSuccess={setSuccess} setSuccessMessage={setSuccessMessage} />}

            <TabContext value={tabIndex}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={tabIndex} onChange={handleChangeTab} aria-label="basic tabs example">
                        <Tab label="Fleet" value='1' />
                        <Tab label="Vehicles" value='2' />
                        <Tab label="Drivers" value='3' />
                    </Tabs>
                </Box>
                <TabPanel value='1'>
                    <FleetTable vehiclesList={vehicleList} driversList={s_drivers} selectedRow={selectedRow} setSelectedRow={setSelectedRow} page={page} rowsPerPage={rowsPerPage} setPage={setPage} setRowsPerPage={setRowsPerPage} />
                </TabPanel>
                <TabPanel value='2'>
                    <VehiclesTable list={vehicleList} selectedRow={selectedRow} setSelectedRow={setSelectedRow} page={page} rowsPerPage={rowsPerPage} setPage={setPage} setRowsPerPage={setRowsPerPage} />
                </TabPanel>
                <TabPanel value='3'>
                    <DriversTable list={driversList} selectedRow={selectedRow} setSelectedRow={setSelectedRow} page={page} rowsPerPage={rowsPerPage} setPage={setPage} setRowsPerPage={setRowsPerPage} />
                </TabPanel>
            </TabContext>

            <Dialog
                open={openConfirmationDialog}
                onClose={handleClose}
            >
                <DialogTitle>
                    {tabIndex === '2' ? 'Delete Vehicle' : 'Delete Driver'}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete this entry?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleCloseConfirm} autoFocus>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar open={success} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <Alert severity="success" sx={{ width: '100%' }} variant="filled">
                    {successMessage}
                </Alert>
            </Snackbar>
        </Box>
    )
}

export default FleetPage;