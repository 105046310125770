import { createSlice } from '@reduxjs/toolkit';
import { red, green, orange, blue } from '@mui/material/colors';

const initialState = {
    appName: 'Transport Tracker',
    appVersion: null,
    appSection: 'Secure',
    appMessage: { open: false, severity: "success", message: "" },
    appTheme: {
        palette: {
            mode: 'light'
            // primary: blue
        },
        typography:{
            fontSize:14,
         }
    },
};


export const appInfoSlice = createSlice({
    name: 'appInfo',
    initialState,

    reducers: {

        setAppVersion: (state, action) => {
            state.appVersion = action.payload
        },

        setAppSection: (state, action) => {
            state.appSection = action.payload
        },

        setAppMessage: (state, action) => {
            // dispatch(setAppMessage({open: true, severity: "success", message: ""}))
            state.appMessage = action.payload
        },

        closeAppMessage: (state) => {
            //closeAppMessage()
            state.appMessage = { ...state.appMessage, open: false, }
        },


        setAppTheme: (state, action) => {
            state.appTheme = action.payload;
        },

        setDarkMode: (state, action) => {
            const palette = { ...state.appTheme.palette, mode: action.payload }
            state.appTheme = { ...state.appTheme, palette }
        },

        setPrimaryColour: (state, action) => {
            const palette = { ...state.appTheme.palette, primary: action.payload }
            state.appTheme = { ...state.appTheme, palette }
        }
    },

});

export const {
    setAppSection,
    setAppTheme,
    setAppVersion,
    setDarkMode,
    setPrimaryColour,
    setAppMessage,
    closeAppMessage,
} = appInfoSlice.actions;


export const appName = (state) => state.appInfo.appName;
export const appVersion = (state) => state.appInfo.appVersion;
export const appTheme = (state) => state.appInfo.appTheme;
export const appSection = (state) => state.appInfo.appSection;
export const appMessage = (state) => state.appInfo.appMessage;



export default appInfoSlice.reducer;
