
const accentColor = '#119dda';

export const createFieldArrY = (doc, fields, dims, fontWeight, splitText, textToPageWidth, page) => {
    doc.setFont('helvetica', fontWeight)
    doc.setFontSize(10);
    doc.setTextColor('#000')

    const fieldOffset = 7.5;
    const fieldArrStartLocation = 30
    let posY = dims.posY

    fields.forEach(field => {
        if (field) {
            if (splitText) {
                var splitedText = doc.splitTextToSize(field, 70);
                doc.text(splitedText, dims.posX, posY)
                posY = posY + (splitedText.length > 0 ? dims.offset * splitedText.length : dims.offset)

            } else {
                if (textToPageWidth) {
                    var pageWidthText = doc.splitTextToSize(field, page.width - page.gutter - 15);
                    doc.text(pageWidthText, dims.posX, posY)
                    posY = posY + (pageWidthText.length > 0 ? dims.offset * pageWidthText.length : dims.offset)
                } else {
                    doc.text(field, dims.posX, posY)
                    posY = posY + dims.offset
                }
            }
        }
    });
    // console.error(posY);
    return posY;
}



export const createTitleText = (doc, text, x, y) => {
    doc.setFont('helvetica', 'normal')
    doc.setFontSize(20);
    doc.setTextColor(accentColor);
    doc.text(text, x, y)

    return doc
}

export const createsubTitleText = (doc, text, x, y) => {
    doc.setFont('helvetica', 'bold')
    doc.setFontSize(12);
    doc.setTextColor('#000')
    doc.text(text, x, y)

    return doc
}